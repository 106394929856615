import { Grd, Bx, Typo } from "@curry-group/mui-curcuma";
import { Hidden } from "@material-ui/core";
import React from "react";
import { IFacility } from "../../../../model/facility";
import { IThing } from "../../../../model/thing";
import { EditControlFreeText } from "../controls/free-text";
import { EditControlNumber } from "../controls/number";

export const EditSemiStationary: React.FC<{
  item?: IThing<IFacility>;
  setItem?: (item: IThing<IFacility>) => void;
}> = ({ item, setItem }) => {
  return (
    <Grd container spacing={4}>
      <Grd item sm={6} xs={12}>
        <EditControlNumber
          value={item?.content?.semiFree}
          onValueChange={(value) => {
            setItem?.({
              ...item,
              content: {
                ...item?.content,
                semiFree: value === "" ? undefined : value,
              },
            });
          }}
        />
      </Grd>
      <Hidden smDown>
        <Grd item sm={6} />
      </Hidden>
      <Grd item xs={12}>
        <Bx mb={1}>
          <Typo variant="h5" color="primary">
            <label htmlFor="field-freetext-semi">Hinweise</label>
          </Typo>
        </Bx>
        <Bx>
          <EditControlFreeText
            id="field-freetext-semi"
            value={item?.content?.description ?? ""}
            onValueChange={(value) => {
              setItem?.({
                ...item,
                content: { ...item?.content, description: value },
              });
            }}
          />
        </Bx>
      </Grd>
    </Grd>
  );
};
