import React, { useState } from 'react';
import { INavigation } from '../../../model/page';
import { Bx, Collapse, Link, Typo, useBreakpoints, Ctn } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';

export const FooterLinks: React.FC<{ navigation?: INavigation }> = ({ navigation }) => {
  const breakpoints = useBreakpoints();
  const categories = navigation?.categories || [];
  const [navOpen, setNavOpen] = useState<{ [key: number]: boolean }>({});
  return (
    <Bx className="print-hidden" bgcolor="#e0e0e0" py={3}>
      <Ctn maxWidth="lg" disableGutters={false}>
        <Bx display="flex" flexDirection={breakpoints.mdDown ? 'column' : undefined} justifyContent={breakpoints.mdDown ? undefined : 'center'}>
          {breakpoints.lgUp &&
            categories.map((cat, idx) => (
              <Bx key={idx} display="flex" flexDirection="column" ml={idx !== 0 ? 10 : undefined}>
                <Link underline="hover" to={'/startseite/' + cat.pageResolved.seo?.alias}>
                  <Typo fontWeight="bold" variant="body2boosted">
                    {cat.pageResolved.content?.titleShort || cat.pageResolved.content?.title}
                  </Typo>
                </Link>
                {cat.pages?.map((page, pageIndex) => (
                  <Bx py={0.5} key={pageIndex}>
                    <Link underline="hover" to={'/startseite/' + cat.pageResolved.seo?.alias + '/' + page.pageResolved.seo?.alias}>
                      <Typo variant="body2">{page.pageResolved.content?.titleShort || page.pageResolved.content?.title}</Typo>
                    </Link>
                  </Bx>
                ))}
              </Bx>
            ))}
          {breakpoints.mdDown &&
            categories.map((cat, idx) => (
              <Bx key={idx} display="flex" alignItems="flex-start" flexDirection="column" mt={idx !== 0 ? 1 : undefined}>
                <Typo fontWeight="bold" variant="body2boosted">
                  <Link underline="none" to={'/startseite/' + cat.pageResolved.seo?.alias}>
                    {cat.pageResolved.content?.titleShort || cat.pageResolved.content?.title}
                  </Link>
                  {cat.pages?.length > 0 && (
                    <FontAwesomeIcon
                      icon={navOpen[idx] ? faChevronUp : faChevronDown}
                      onClick={() => {
                        setNavOpen({ [idx]: !navOpen[idx] });
                      }}
                      style={{ marginLeft: '.75rem', marginTop: '.4rem', fontWeight: 700, position: 'absolute' }}
                    />
                  )}
                </Typo>
                <Collapse expanded={navOpen[idx] ?? false}>
                  <Bx display="flex" flexDirection="column" alignItems="flex-start">
                    {cat.pages?.map((page, pageIndex) => (
                      <Bx py={0.5} key={pageIndex}>
                        <Typo variant="body2">
                          <Link underline="none" to={'/startseite/' + cat.pageResolved.seo?.alias + '/' + page.pageResolved.seo?.alias}>
                            {page.pageResolved.content?.titleShort || page.pageResolved.content?.title}
                          </Link>
                        </Typo>
                      </Bx>
                    ))}
                  </Bx>
                </Collapse>
              </Bx>
            ))}
        </Bx>
      </Ctn>
    </Bx>
  );
};
