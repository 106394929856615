import React from 'react';
import { Grd, Bx, Typo, Btn } from '@curry-group/mui-curcuma';
import { IWidget } from '../../model/page';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

export const SearchBoxes: React.FC<{ widgets?: IWidget[] }> = ({ widgets }) => {
  const theme = useTheme();
  const borderColor = ['tertiary.light', 'secondary.light', 'secondary.main', 'primary.main'];
  const history = useHistory();
  return (
    <Bx component="section" my={3} className="print-hidden">
      <Grd container spacing={2}>
        {(widgets || []).map((item, idx) => {
          return (
            <Grd item xs={12} sm={6} lg={3} key={idx}>
              <Bx
                bgcolor="background.paper"
                style={{
                  borderTop: `5px solid ${borderColor[idx % borderColor.length]}`,
                  minHeight: 300,
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[3]
                }}
                p={3}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Typo variant="h3" component="h3" gutterBottom>
                  {item.title}
                </Typo>
                <Bx display="flex" flexDirection="column" justifyContent="flex-start" style={{ minHeight: 150 }} height="100%">
                  <Typo variant="body1">{item.description}</Typo>
                </Bx>
                <Bx mt="auto">
                  <Btn
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push(item.link ?? '/startseite');
                    }}
                    fullWidth
                  >
                    {item.linkText}
                  </Btn>
                </Bx>
              </Bx>
            </Grd>
          );
        })}
      </Grd>
    </Bx>
  );
};
