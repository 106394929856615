import { Bx, Link, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const LogoHome: React.FC = () => {
  const breakpoint = useBreakpoints();
  const location = useLocation();

  return (
    <a href="/startseite" style={{ color: 'currentcolor', textDecoration: 'none' }}>
      <Bx display="flex" alignItems="center">
        <Bx mr={1}>
          <img
            src="/assets/STER_Bildmarke.png"
            alt="Logo Stadt Erlangen"
            style={{
              maxHeight: breakpoint.smDown ? '32px' : breakpoint.md ? '42px' : '59px',
              display: 'block'
            }}
          />
        </Bx>
        <Bx mr={1}>
          <img
            src="/assets/logo-erh.png"
            alt="Logo Erlangen-Höchstadt"
            style={{
              maxHeight: breakpoint.smDown ? '32px' : breakpoint.md ? '42px' : '59px',
              display: 'block'
            }}
          />
        </Bx>
        <Bx>
          <Typo variant={breakpoint.smDown ? 'h6' : breakpoint.md ? 'h5' : 'h3'} component="h1" style={{ display: 'block' }}>
            Pflegeplatzbörse
          </Typo>
          <Typo
            component="h2"
            variant="body2"
            style={{
              fontSize: breakpoint.xsDown ? 8 : breakpoint.md ? 12 : undefined,
              display: 'block',
              lineHeight: 1,
              overflow: 'visible'
            }}
            noWrap
          >
            {breakpoint.md ? (
              <>
                Stadt Erlangen &<br /> Landkreis Erlangen-Höchstadt
              </>
            ) : (
              <>Stadt Erlangen & Landkreis Erlangen-Höchstadt</>
            )}
          </Typo>
        </Bx>
      </Bx>
    </a>
  );
};
