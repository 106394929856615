import { Grd, Bx, Btn, IconBtn, Typo, Ctn } from '@curry-group/mui-curcuma';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../model/app-context';

export const LoginForm: React.FC<{ onLogin?: () => void; closeButton?: boolean; onClose?: () => void }> = ({ closeButton, onClose, onLogin }) => {
  const history = useHistory();
  const [loginError, setLoginError] = useState('');
  const appState = useContext(AppContext);
  return (
    <Ctn disableGutters={false} maxWidth="md">
      <form
        onSubmit={(ev: any) => {
          ev.preventDefault();
          const formData = new FormData(ev.target);
          const postObject: {
            [key: string]: any;
          } = { persist: true };
          for (let item of Array.from(formData.entries())) {
            postObject[item[0]] = item[1];
          }
          fetch('/uwao-api/core/auth/credentiallogin', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(postObject)
          }).then(response => {
            if (response.ok) {
              setLoginError('');
              onLogin?.();
              appState.startup?.()?.then(() => {
                history.push('/my-facilities');
              });
            } else {
              setLoginError('Bitte überprüfen Sie den Benutzernamen und das Passwort.');
              appState.startup?.();
            }
          });
        }}
      >
        <Bx display="flex" flexDirection="column" justifyContent="center" pt={4} pb={4}>
          <Grd container spacing={2}>
            <Grd item xs={12}>
              <Bx display="flex" justifyContent="space-between">
                <Typo variant="h3">Login</Typo>
                <IconBtn size="small">
                  <FontAwesomeIcon icon={faTimes} onClick={onClose} />
                </IconBtn>
              </Bx>
            </Grd>
            <Grd item xs={12}>
              <TextField id="login-username" name="username" label="Benutzername" autoComplete="username" fullWidth />
            </Grd>
            <Grd item xs={12}>
              <TextField id="login-password" name="password" label="Passwort" autoComplete="current-password" type="password" fullWidth />
            </Grd>
            {!!loginError && (
              <Grd item xs={12}>
                <Typo color="error" role="alert">{loginError}</Typo>
              </Grd>
            )}
            <Grd item xs={12}>
              <Btn variant="contained" color="primary" type="submit" fullWidth>
                Anmelden
              </Btn>
            </Grd>
          </Grd>
        </Bx>
      </form>
    </Ctn>
  );
};
