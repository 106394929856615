import React from 'react';
import { Drawer } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { SearchFilter } from '../../search-filter';
import { Bx, Btn, useBreakpoints } from '@curry-group/mui-curcuma';

export const MobileFilter: React.FC<{ mobileFilter?: boolean; setMobileFilter?: (value: boolean) => void }> = ({ mobileFilter, setMobileFilter }) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints();
  return (
    <Drawer
      anchor="left"
      open={mobileFilter}
      onClose={() => {
        setMobileFilter?.(false);
      }}
      style={{ zIndex: 10000 }}
      PaperProps={{ style: { overflowX: 'hidden' } }}
    >
      <Bx
        height="100%"
        width="350px"
        style={{ paddingTop: breakpoint.smDown ? `${theme?.custom?.project?.appBar?.mobile?.height}px` : `${theme?.custom?.project?.appBar?.desktop?.height}px` }}
      >
        <Bx p={3}>
          <SearchFilter asSidebar hideSearch />
          <Bx mt={3} height={46}>
            <Btn
              fullWidth
              color="primary"
              variant="contained"
              style={{ position: 'fixed', bottom: 16, maxWidth: 302 }}
              onClick={() => {
                setMobileFilter?.(false);
              }}
            >
              Übernehmen
            </Btn>
          </Bx>
        </Bx>
      </Bx>
    </Drawer>
  );
};
