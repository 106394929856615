import { Btn } from '@curry-group/mui-curcuma';
import { faArrowAltFromLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const SearchButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <Btn size="large" variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faArrowAltFromLeft} />} onClick={onClick}>
      Suchen
    </Btn>
  );
};
