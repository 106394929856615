import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { IFacility } from '../../../../model/facility';
import { IThing } from '../../../../model/thing';
import { Marker } from 'react-leaflet';
import { DivIcon } from 'leaflet';

const useStyles = makeStyles({
  marker: {
    border: 0
  }
});

export const SearchResultsMapMarker: React.FC<{
  item?: IThing<IFacility>;
  count?: number;
  onClick?: (item: IThing<IFacility>) => void;
}> = ({ item, count, onClick }) => {
  const styles = useStyles();
  const [mapMarker, setMapMarker] = useState<DivIcon | undefined>(undefined);
  const fallbackImgUrl = '/assets/facility-fallback.jfif';
  const imageUrl = item?.content?.keyvisual?.fullPath ? '/uwao-api/core/asset/' + item.content.keyvisual.fullPath + '?tn=1&s=gallery_m' : fallbackImgUrl;
  useEffect(() => {
    let html = '<div style="width:80px; height:80px; position:relative;">';
    html += '<img alt="" style="width:100%; height:100%; display:block; position:absolute;" src="/assets/leaflet/images/marker-shadow.svg"></img>';
    html += '<img alt="" style="border-radius:100px; display:block; width:55px; height:55px; position:absolute; left:13px; top:6px;" src="' + imageUrl + '"></img>';
    if ((count ?? 0) > 1) {
      html +=
        '<div style="position:absolute; display:flex; justify-content: center; align-items: center; top: 5px; right: 5px; background-color: red; width: 20px; height: 20px; border-radius: 100px; color:white;">' +
        count +
        '</div';
    }
    html += '</div>';
    const leafletMarker = new DivIcon({
      iconSize: [80, 80],
      iconAnchor: [40, 80],
      html,
      className: styles.marker
    });
    setMapMarker(leafletMarker);
  }, [count, imageUrl, styles.marker]);
  if (!item?.content?.lat || !item?.content?.lon || !mapMarker) {
    return null;
  }
  const latLon: [number, number] = [item.content.lat, item.content.lon];
  return (
    <Marker
      position={latLon}
      icon={mapMarker}
      eventHandlers={{
        click: ev => {
          onClick?.(item);
        }
      }}
    />
  );
};
