import { theme } from "@curry-group/mui-curcuma";
import { ICurcumaTheme } from "@curry-group/mui-curcuma/dist/ui/Theme/ThemeProvider";
import { createMuiTheme, Theme } from "@material-ui/core/styles";

const customBreakpoints: ICurcumaTheme["breakpoints"] = {
  ...theme.breakpoints,
  values: {
    xs: 0,
    sm: 600,
    md: 1100,
    lg: 1426,
    xl: 1920,
    wqhd: 2400,
    uhd: 3840,
  },
};

const customPalette: ICurcumaTheme["palette"] = {
  primary: {
    light: "#579D88",
    main: "#298167",
    dark: "#206953",
    contrastText: "#fff",
  },
  secondary: {
    light: "#85B9AA",
    main: "#579D88",
    dark: "#298167",
    contrastText: "#fff",
  },
  tertiary: {
    light: "#B3D5CB",
    main: "#85B9AA",
    dark: "#579D88",
    contrastText: "#19503f",
  },
  quaternary: {
    light: "#e5f5f0",
    main: "#B3D5CB",
    dark: "#85B9AA",
    contrastText: "#19503f",
  },
  accent: {
    light: "#f8ae6d",
    main: "#EB9C56",
    dark: "#a75e1f",
    contrastText: "#202020",
  },
  text: {
    ...theme.palette.text,
    primary: "#202020",
    secondary: "#767676",
  },
  background: {
    paper: "#fff",
    default: "#fafafa",
  },
  divider: "#bfbfbf",
};

const customTypography: ICurcumaTheme["typography"] = {
  fontFamily: "'Lato', Helvetica, Arial, sans-serif",
  fontSize: 16,
  fontWeightLight: 400,
  fontWeightRegular: 400,
  fontWeightMedium: 700,
  fontWeightBold: 700,
  h1: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h2: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1.75rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h3: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h4: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1.25rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h5: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h6: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  subtitle1: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.688,
    letterSpacing: 0,
  },
  subtitle2: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.688,
    letterSpacing: 0,
  },
  body1: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "1.125rem",
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  body2: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.688,
    letterSpacing: 0,
  },
  button: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: 1.5,
    textTransform: "none",
    letterSpacing: 0,
  },
  caption: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.66,
  },
  overline: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 2.66,
    textTransform: "uppercase",
  },
  h1boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "2.25rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h2boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h3boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1.75rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h4boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h5boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1.25rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  h6boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  body1boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "1.25rem",
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  body2boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.688,
    letterSpacing: 0,
  },
  body3boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  body3: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "0.625rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  subtitle1boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "1.25rem",
    lineHeight: 1.688,
    letterSpacing: 0,
  },
  subtitle2boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.688,
    letterSpacing: 0,
  },
  subtitle3boosted: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  subtitle3: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "0.625rem",
    lineHeight: 1.25,
    letterSpacing: 0,
  },
};

const customShadows: ICurcumaTheme["shadows"] = [
  "none",
  "0 5px 15px 0 rgba(0,0,0,.05)",
  "0 5px 15px 0 rgba(0,0,0,.10)",
  "0 5px 15px 0 rgba(0,0,0,.15)",
  "0 5px 15px 0 rgba(0,0,0,.2)",
  "0 5px 15px 0 rgba(0,0,0,.25)",
  "0 5px 15px 0 rgba(0,0,0,.3)",
  "0 5px 15px 0 rgba(0,0,0,.35)",
  "0 5px 15px 0 rgba(0,0,0,.4)",
  "0 5px 15px 0 rgba(0,0,0,.45)",
  "0 5px 15px 0 rgba(0,0,0,.5)",
  "0 5px 15px 0 rgba(0,0,0,.55)",
  "0 5px 15px 0 rgba(0,0,0,.6)",
  "0 5px 15px 0 rgba(0,0,0,.65)",
  "0 5px 15px 0 rgba(0,0,0,.7)",
  "0 5px 15px 0 rgba(0,0,0,.75)",
  "0 5px 15px 0 rgba(0,0,0,.8)",
  "0 5px 15px 0 rgba(0,0,0,.85)",
  "0 5px 15px 0 rgba(0,0,0,.9)",
  "0 5px 15px 0 rgba(0,0,0,.95)",
  "0 5px 15px 0 rgba(0,0,0,1)",
  "0 5px 15px 0 rgba(0,0,0,.35)",
  "0 5px 15px 0 rgba(0,0,0,.4)",
  "0 5px 15px 0 rgba(0,0,0,.4)",
  "0 5px 15px 0 rgba(0,0,0,.4)",
];

const customOverides: ICurcumaTheme["overrides"] = {
  MuiButton: {
    iconSizeSmall: {
      "& > *:first-child": {
        fontSize: 14,
      },
    },
    iconSizeMedium: {
      "& > *:first-child": {
        fontSize: 16,
      },
    },
    iconSizeLarge: {
      "& > *:first-child": {
        fontSize: 18,
      },
    },
    root: {
      padding: "10px 18px",
      fontSize: "1rem",
      borderRadius: 4,
      "&.Mui-disabled": {
        color: "#A7B4B8",
      },
    },
    outlined: {
      borderColor: "#000",
      padding: "10px 18px",
      fontSize: "1rem",
      borderRadius: 4,
    },
    text: {
      padding: "10px 18px",
      fontSize: "1rem",
      borderRadius: 4,
      border: "1px solid transparent !important",
    },
    sizeSmall: {
      padding: "2px 18px",
      fontSize: "0.875rem",
      borderRadius: 4,
    },
    textSizeSmall: {
      padding: "2px 18px",
      fontSize: "0.875rem",
      borderRadius: 4,
    },
    sizeLarge: {
      padding: "10px 24px",
      fontSize: "1.125rem",
      borderRadius: 4,
    },
    textSizeLarge: {
      padding: "10px 24px",
      fontSize: "1.125rem",
      borderRadius: 4,
    },
    contained: {
      border: "1px solid transparent",
    },
    containedPrimary: {
      "&.Mui-disabled": {
        backgroundColor: "#A7B4B8",
        color: "#fff",
      },
    },
    containedSecondary: {
      "&.Mui-disabled": {
        backgroundColor: "#A7B4B8",
        color: "#fff",
      },
    },
    outlinedPrimary: {
      "&.Mui-disabled": {
        color: "#A7B4B8",
        borderColor: "#A7B4B8",
      },
    },
    outlinedSecondary: {
      "&.Mui-disabled": {
        color: "#A7B4B8",
        borderColor: "#A7B4B8",
      },
    },
    textPrimary: {
      "&.Mui-disabled": {
        color: "#A7B4B8",
      },
    },
    textSecondary: {
      "&.Mui-disabled": {
        color: "#A7B4B8",
      },
    },
  },
  MuiFormControl: {
    root: {
      "& .MuiInputLabel-animated": {
        color: "#7e7e7e",

        "&.Mui-focused": {
          color: "#484848",
        },
      },
    },
  },
  MuiInput: {
    root: {
      padding: "5px 0",
    },
  },

  MuiAutocomplete: {
    paper: {
      backgroundColor: customPalette.background?.paper,
      color: customPalette?.text?.primary,
      boxShadow: customShadows[7],

      '& li[aria-selected="true"]': {
        backgroundColor: customPalette?.primary?.dark,
        color: customPalette?.primary?.contrastText,
      },

      '& li[data-focus="true"]': {
        backgroundColor: customPalette?.tertiary?.light,
        color: customPalette?.quaternary?.contrastText,
      },
    },
  },
  MuiSlider: {
    thumb: {
      marginTop: -12,
      marginLeft: -12,
      width: 25,
      height: 25,
    },
    valueLabel: {
      left: "calc(-50% - -10px)",
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: "0.875rem",
    },
  },
  MuiCardActionArea: {
    focusHighlight: {
      backgroundColor: customPalette.primary?.main,
    },
    root: {
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
      "&:hover": {
        backgroundColor: customPalette.primary?.main,
        "& .typo": {
          color: customPalette.primary?.contrastText,
        },
        "& .OverflowBadge": {
          transform: "translateY(-15px)",
        },
      },
      "& .typo": {
        transition: theme.transitions.create("color", {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
  },
  MuiOutlinedInput: {
    input: {
      borderRadius: 8,
      color: "#000",
    },
    root: {
      backgroundColor: "#fff",
      borderRadius: 8,
      "& > .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.42)",
      },
      "&:not(.Mui-disabled):hover:not(.Mui-focused) > .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "rgba(0, 0, 0, 0.42)",
        },
      "&:not(.Mui-disabled).Mui-focused > .MuiOutlinedInput-notchedOutline": {
        borderColor: customPalette?.primary?.main,
      },
      "&.Mui-disabled": {
        borderColor: "#D6D7D9",
        borderWidth: 1,
        borderStyle: "solid",
        backgroundColor: "rgba(255,255,255,.7)",
      },
      "&.Mui-disabled > *": {
        cursor: "not-allowed",
        opacity: 0.5,
      },
    },
  },
  MuiCssBaseline: {
    "@global": {
      ".CustomizedTouchRipple": {
        color: customPalette.primary?.main,
      },
      ".CustomizedCard": {
        height: "100%",
        transition: theme.transitions.create("box-shadow", {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
        "&:hover": {
          boxShadow: customShadows[9],
        },
      },
      ".OverflowBadge": {
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
      "table.no-border": {
        "& th, td": {
          border: "0 !important",
        },
      },
      "#detail-tabs": {
        height: 72,
        "@media (min-width: 1100px)": {
          overflow: "visible",
        },

        "& .MuiTabs-root": {
          "@media (min-width: 1100px)": {
            overflow: "visible",
          },
        },

        "& .MuiTabs-flexContainer": {
          height: 72,
          "@media (min-width: 1100px)": {
            overflow: "visible",
          },
        },

        "& .MuiTabs-scroller": {
          height: 72,
          "@media (min-width: 1100px)": {
            overflow: "visible",
          },

          "& .MuiTabs-indicator": {
            height: "3px",
            // display: 'none'
            "&:after": {
              content: '""',
              position: "absolute",
              left: "calc(50% - 10px)",
              right: 0,
              bottom: -10,
              width: 0,
              height: 10,
              borderStyle: "solid",
              borderWidth: "6px 10px 0 10px",
              borderColor:
                customPalette.primary?.main +
                " transparent transparent transparent",
            },
          },
          "& .MuiTab-root": {
            marginRight: 0,
            fontSize: "17px",
            fontWeight: "bold",
            color: customPalette.text?.primary,
            padding: theme.spacing(1, 2),
            position: "relative",
            textDecoration: "underline",
            "@media (min-width: 1100px)": {
              overflow: "visible",
            },

            "&:hover": {
              color: customPalette.primary?.main,
            },

            "&.Mui-selected": {
              backgroundColor: customPalette.primary?.main,
              color: customPalette.primary?.contrastText,
              textDecoration: "none",

              "&:hover": {
                color: customPalette.primary?.contrastText,
              },
            },
          },
        },
      },
    },
  },
};

const customCustom: ICurcumaTheme["custom"] = {
  iconBtn: {
    medium: {
      width: 46,
      height: 46,
      fontSize: 22,
      borderRadiusRounded: 4,
    },
  },
  checkboxControl: {
    icon: {
      contained: {
        border: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },
  },
  switchControl: {
    base: {
      width: 48,
      height: 24,
      margin: 8,
      padding: 1,
      thumbWidth: 24,
      thumbHeight: 24,
      thumbRadius: "50%",
      transform: "translateY(-1px) translateX(-1px)",
      transformActive: "translateY(-1px) translateX(23px)",
    },
    default: {
      borderColor: "#000",
      backgroundColor: "transparent",
      backgroundColorActive: "#000",
      thumbBackgroundColor: "#000",
      thumbBackgroundColorActive: "#fff",
    },
    primary: {
      borderColor: "#E1F1EC",
      backgroundColor: "#E1F1EC",
      backgroundColorActive: customPalette?.primary?.main,
      thumbBackgroundColor: customPalette?.primary?.main,
      thumbBackgroundColorActive: "#E1F1EC",
    },
    secondary: {
      borderColor: "#000",
      backgroundColor: "transparent",
      backgroundColorActive: "#000",
      thumbBackgroundColor: "#000",
      thumbBackgroundColorActive: "#fff",
    },
    tertiary: {
      borderColor: "#000",
      backgroundColor: "transparent",
      backgroundColorActive: "#000",
      thumbBackgroundColor: "#000",
      thumbBackgroundColorActive: "#fff",
    },
    quaternary: {
      borderColor: "#000",
      backgroundColor: "transparent",
      backgroundColorActive: "#000",
      thumbBackgroundColor: "#000",
      thumbBackgroundColorActive: "#fff",
    },
  },
  listItem: {
    backgroundColor: "transparent",
    backgroundColorActive: customPalette?.primary?.main,
    backgroundColorActiveHover: customPalette?.primary?.light,
    backgroundColorHover: customPalette?.primary?.light,
  },
  listWrapper: {
    borderColor: "#bfbfbf",
  },
  divider: {
    strokeColor: customPalette?.divider,
  },
  project: {
    appBar: {
      desktop: {
        height: 86,
      },
      mobile: {
        height: 56,
      },
    },
  },
};

export const customTheme: ICurcumaTheme = createMuiTheme({
  ...theme,
  shadows: customShadows as Theme["shadows"],
  overrides: { ...customOverides },
  breakpoints: { ...customBreakpoints },
  typography: { ...customTypography },
  palette: { ...customPalette },
  custom: { ...customCustom },
  zIndex: {
    tooltip: 50000,
    modal: 11000,
  },
});
