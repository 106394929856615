import React, { FC, useEffect } from 'react';
import { initialize } from './lib';
import { IReadSpeakerConfig } from './lib';

export function useReadSpeaker(customerId: number, onLoadCallback?: Function, config?: IReadSpeakerConfig) {
  useEffect(() => {
    initialize(customerId, onLoadCallback, config);
  }, [customerId, onLoadCallback, config]);
}

export interface IReadSpeakerProps {
  /**
   * Mandantenidentifikation
   */
  customerId: number;

  /**
   * Wird aufgerufen, wenn das ReadSpeaker script erfolgreich geladen wurde
   */
  config?: IReadSpeakerConfig;

  /**
   * Optionale ReadSpeaker Konfiguration
   */
  onLoadCallback?: Function;
}

/**
 *
 * @param customerId Mandantenidentifikation
 * @param onLoadCallback Wird aufgerufen, wenn das ReadSpeaker script erfolgreich geladen wurde
 * @param config Optionale ReadSpeaker Konfiguration
 * @returns
 */
export const ReadSpeaker: FC<IReadSpeakerProps> = ({ customerId, onLoadCallback, config }) => {
  useReadSpeaker(customerId, onLoadCallback, config);
  return <></>;
};

export { ReadSpeakerButton } from './button';
