import React, { useEffect, useState } from 'react';
import { Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Ctn, Grd, Bx, Typo, TabList, TabItem, useBreakpoints, Btn } from '@curry-group/mui-curcuma';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faMap, faTh } from '@fortawesome/pro-light-svg-icons';

import { IThing } from '../../model/thing';
import { IFacility } from '../../model/facility';
import { FacilityListItem } from '../facility-item/list';
import { FacilityGridItem } from '../facility-item/grid';
import { SearchResultsMap } from './map';
import { SearchFilter } from '../search-filter';
import { MobileFilter } from './mobile-filter';
import { PrintButton } from '../print-button';

export const SearchResults: React.FC = () => {
  const theme = useTheme();
  const breakpoints = useBreakpoints();
  const history = useHistory();
  const loc = useLocation();
  const [facilities, setFacilities] = useState<IThing<IFacility>[]>([]);
  const [mobileFilter, setMobileFilter] = useState(false);
  const query = new URLSearchParams(loc.search);
  const layout = query.get('lt') ?? 'grid';
  useEffect(() => {
    const load = async () => {
      const response = await fetch('/uwao-api/care-erlangen/facilities' + loc.search);
      const json = await response.json();
      setFacilities(json);
    };
    load();
  }, [loc.search]);
  const gridElement = breakpoints.smUp ? (
    <Bx display="flex">
      <Bx mr={1}>
        <FontAwesomeIcon icon={faTh} />
      </Bx>
      Raster
    </Bx>
  ) : (
    <FontAwesomeIcon icon={faTh} />
  );
  const listElement = breakpoints.smUp ? (
    <Bx display="flex">
      <Bx mr={1}>
        <FontAwesomeIcon icon={faList} />
      </Bx>
      Liste
    </Bx>
  ) : (
    <FontAwesomeIcon icon={faList} />
  );
  const mapElement = breakpoints.smUp ? (
    <Bx display="flex">
      <Bx mr={1}>
        <FontAwesomeIcon icon={faMap} />
      </Bx>
      Karte
    </Bx>
  ) : (
    <FontAwesomeIcon icon={faMap} />
  );
  return (
    <Bx pt={breakpoints.mdUp ? 10 : 5} pb={10} height="100%" display="flex" flexDirection="column">
      <MobileFilter mobileFilter={mobileFilter} setMobileFilter={setMobileFilter} />
      <Ctn maxWidth="lg" disableGutters={false}>
        <Bx width="100%" pb={3} display="flex" justifyContent="space-between">
          <Typo component="h3" className="print-as-h2" variant="h1" color="primary">
            Ihre Suche ergab {facilities?.length ?? 0} Treffer
          </Typo>
          <PrintButton />
        </Bx>
      </Ctn>
      <Ctn maxWidth="lg" disableGutters={false}>
        <Bx className="print-hidden" display="flex" justifyContent="space-between" pb={3}>
          <Hidden mdUp>
            <Btn
              color="primary"
              variant="contained"
              onClick={() => {
                setMobileFilter(true);
              }}
            >
              Filtern
            </Btn>
          </Hidden>
          <Bx className="print-hidden" display="flex" justifyContent="flex-end" width="100%">
            <TabList
              value={layout}
              onChange={
                ((ev: any, b: any) => {
                  const params = new URLSearchParams(loc.search);
                  if (b !== 'grid') {
                    params.set('lt', b);
                  } else {
                    params.delete('lt');
                  }
                  history.push('/search?' + params.toString());
                }) as any
              }
            >
              <TabItem label={gridElement} value={'grid'} disableRipple />
              <TabItem label={listElement} value={'list'} disableRipple />
              <TabItem label={mapElement} value={'map'} disableRipple />
            </TabList>
          </Bx>
        </Bx>
      </Ctn>
      {/* {['list', 'grid'].includes(layout) && ( */}
      <Ctn maxWidth="lg" disableGutters={false}>
        <Grd container spacing={6}>
          <Hidden smDown>
            <Grd className="print-hidden" item md={3} style={{ borderRight: `1px solid ${theme.palette.divider}` }}>
              {/* <Bx position="sticky" top={116}> */}
              <SearchFilter asSidebar hideSearch />
              {/* </Bx> */}
            </Grd>
          </Hidden>
          <Grd item xs={12} md={9}>
            <Grd container spacing={3}>
              {layout === 'list' &&
                facilities.map(fi => {
                  return (
                    <Grd item xs={12} key={fi._id}>
                      <FacilityListItem item={fi} to={'/detail/' + fi.seo?.alias + loc.search} />
                    </Grd>
                  );
                })}
              {layout === 'grid' &&
                facilities.map(fi => {
                  return (
                    <Grd item xs={12} sm={6} lg={4} key={fi._id}>
                      <FacilityGridItem item={fi} to={'/detail/' + fi.seo?.alias + loc.search} />
                    </Grd>
                  );
                })}
              {layout === 'map' && (
                <Grd item xs={12}>
                  <SearchResultsMap items={facilities} />
                </Grd>
              )}
            </Grd>
          </Grd>
        </Grd>
      </Ctn>
      {/* )} */}
    </Bx>
  );
};
