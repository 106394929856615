import { Typo, Bx, IUnsortedListProps, useBreakpoints, UnsortedList } from '@curry-group/mui-curcuma';

const CircleIcon = () => (
  <>
    <Bx display="flex" alignItems="baseline" height="100%" className="print-hidden">
      <Bx width={8} height={8} borderRadius="full" bgcolor="text.secondary" style={{ marginTop: 10 }}></Bx>
    </Bx>
    <span className="print-only">•</span>
  </>
);

export const OuterListBx: React.FunctionComponent = ({ children }) => (
  <Bx display="inline-block" color="primary.main" width={50} textAlign="center">
    {children}
  </Bx>
);
export const InnerListBx: React.FunctionComponent = ({ children }) => (
  <Bx display="inline-block" color="primary.main" width={50} textAlign="center" style={{ marginLeft: 10 }} fontWeight="bold">
    {children}
  </Bx>
);

export const BoxedUnsortedList: React.FunctionComponent<{
  title: string;
  items: string[] | React.ReactNode[];
  subHeadline?: React.ReactNode;
  fullHeight?: boolean;
  asGrid?: boolean;
}> = ({ title, items, subHeadline, fullHeight, asGrid }) => {
  let itemsWithIcons: IUnsortedListProps['items'] = [];
  items &&
    items.length &&
    items.forEach(element => {
      itemsWithIcons?.push({ children: element, icon: <CircleIcon /> });
    });
  const breakpoints = useBreakpoints();
  let gridTemplateColumns;
  if (breakpoints.lgUp) {
    gridTemplateColumns = '1fr 1fr 1fr';
  } else if (breakpoints.md) {
    gridTemplateColumns = '1fr 1fr';
  } else {
    gridTemplateColumns = '1fr';
  }

  return (
    <Bx bgcolor="background.paper" boxShadow={2} borderRadius="borderRadius" p={3} height={fullHeight ? '100%' : undefined}>
      <Bx mb={2}>
        <Typo component="h5" variant="h3" color="primary">
          {title}
        </Typo>
      </Bx>
      {subHeadline && <Bx mb={2}>{subHeadline}</Bx>}
      <Typo component="div">
        {asGrid ? (
          <UnsortedList
            spacing={2}
            iconSpacing={1.5}
            items={itemsWithIcons}
            className="unsorted-list-as-grid"
            style={{ display: 'grid', gridTemplateColumns, gap: '0 16px' }}
          />
        ) : (
          <UnsortedList spacing={2} iconSpacing={1.5} items={itemsWithIcons} />
        )}
      </Typo>
    </Bx>
  );
};
