import { Bx, CheckboxControl, Collapse } from '@curry-group/mui-curcuma';
import React, { useEffect, useState } from 'react';
import { IReferenceItem } from '../../../model/facility';
import { CollapsibleFilterHead } from '../collapsible-filter-head';

export const StationaryFilter: React.FC<{
  collapsible?: boolean;
  hideExtended?: boolean;
  carePlaceTypes: number[];
  setCarePlaceTypes: (value: number[]) => void;
  specialCareOffers: number[];
  setSpecialCareOffers: (value: number[]) => void;
}> = ({ collapsible, hideExtended, carePlaceTypes, specialCareOffers, setCarePlaceTypes, setSpecialCareOffers }) => {
  const [carePlaceTypesData, setCarePlacesTypesData] = useState<IReferenceItem[]>([]);
  const [specialCareOffersData, setSpecialCareOffersData] = useState<IReferenceItem[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [extendedExpanded, setExtendedExpanded] = useState<boolean>(false);

  useEffect(() => {
    const loadCpt = async () => {
      const cptProm = fetch('/uwao-api/care-erlangen/filteritems/careplacetypes');
      const cptResponse = await cptProm;
      const cptJson = await cptResponse.json();
      setCarePlacesTypesData(cptJson);
    };
    const loadScp = async () => {
      const scoProm = fetch('/uwao-api/care-erlangen/filteritems/specialcareoffers');
      const scoResponse = await scoProm;
      const scoJson = await scoResponse.json();
      setSpecialCareOffersData(scoJson);
    };
    loadCpt();
    if (!hideExtended) {
      loadScp();
    }
  }, [hideExtended]);

  const RegularFilters = () => {
    return (
      <>
        {carePlaceTypesData.map((s, idx) => {
          return (
            <Bx key={s._id} mb={idx !== carePlaceTypesData.length - 1 ? 0.5 : undefined} width="100%">
              <CheckboxControl
                label={s.content?.name}
                control={{ color: 'primary' }}
                checked={carePlaceTypes.includes(s.sequentialNumber ?? 0)}
                onChange={(e, v) => {
                  if (s.sequentialNumber !== undefined) {
                    if (v) {
                      setCarePlaceTypes([...carePlaceTypes, s.sequentialNumber]);
                    } else {
                      setCarePlaceTypes(carePlaceTypes.filter(cpt => cpt !== s.sequentialNumber));
                    }
                  }
                }}
              />
              {/* <CheckBxCtrl
                id={s._id}
                label={s.content?.name}
                checked={carePlaceTypes.includes(s.sequentialNumber ?? 0)}
                onChange={(e, v) => {
                  if (s.sequentialNumber !== undefined) {
                    if (carePlaceTypes.includes(s.sequentialNumber ?? 0)) {
                      setCarePlaceTypes(carePlaceTypes.filter(cpt => cpt !== s.sequentialNumber));
                    } else {
                      setCarePlaceTypes([...carePlaceTypes, s.sequentialNumber]);
                    }

                    // if (v) {
                    //   setCarePlaceTypes([...carePlaceTypes, s.sequentialNumber]);
                    // } else {
                    //   setCarePlaceTypes(carePlaceTypes.filter(cpt => cpt !== s.sequentialNumber));
                    // }
                  }
                }}
              /> */}
            </Bx>
          );
        })}
      </>
    );
  };

  const ExtendedFilters = () => {
    return (
      <>
        {specialCareOffersData.map((s, idx) => {
          return (
            <Bx key={s._id} mb={idx !== specialCareOffersData.length - 1 ? 0.5 : undefined} width="100%">
              <CheckboxControl
                label={s.content?.name}
                control={{ color: 'primary' }}
                checked={specialCareOffers.includes(s.sequentialNumber ?? 0)}
                onChange={(e, v) => {
                  if (s.sequentialNumber !== undefined) {
                    if (v) {
                      setSpecialCareOffers([...specialCareOffers, s.sequentialNumber]);
                    } else {
                      setSpecialCareOffers(specialCareOffers.filter(sco => sco !== s.sequentialNumber));
                    }
                  }
                }}
              />
            </Bx>
          );
        })}
      </>
    );
  };

  return (
    <>
      {collapsible ? (
        <>
          <CollapsibleFilterHead title="Art der Pflege" expanded={expanded} onClick={() => setExpanded(!expanded)} />
          <Collapse expanded={expanded}>
            <Bx py={1.5} px={0.25}>
              <RegularFilters />
            </Bx>
          </Collapse>
        </>
      ) : (
        <RegularFilters />
      )}

      {!hideExtended && (
        <>
          {collapsible ? (
            <Bx mt={3} width="100%">
              <CollapsibleFilterHead title="Weitere Filter" expanded={extendedExpanded} onClick={() => setExtendedExpanded(!extendedExpanded)} />
              <Collapse expanded={extendedExpanded}>
                <Bx py={1.5} px={0.25}>
                  <ExtendedFilters />
                </Bx>
              </Collapse>
            </Bx>
          ) : (
            <ExtendedFilters />
          )}
        </>
      )}
    </>
  );
};

// Performance Test
// const CheckBxCtrl: React.FunctionComponent<{ id: string; label?: string; checked?: boolean; onChange?: (e: any, v: any) => void }> = ({ id, label, checked, onChange }) => {
//   return (
//     <>
//       <input id={id} type="checkbox" onChange={onChange as any} checked={checked} />
//       <label htmlFor={id}>{label}</label>
//     </>
//   );
// };
