import React from 'react';
import { IContact } from '../../model/page';
import { Bx, Link, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faLandmark, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { IThing } from '../../model/thing';
import { useTheme } from '@material-ui/core/styles';

export const ContactCard: React.FC<{ contact: IThing<IContact> }> = ({ contact }) => {
  const theme = useTheme();

  return (
    <Bx style={{ border: '1px solid #bfbfbf', borderRadius: theme.shape.borderRadius }} bgcolor="background.paper" p={1}>
      <Bx pb={2}>
        <Typo variant="body2boosted" fontWeight="bold">
          {contact.content?.name}
        </Typo>
        <Typo variant="body2boosted">{contact.content?.location}</Typo>
      </Bx>

      <Bx pb={1}>
        <Bx display="flex">
          <Bx pr={1} width={30} mt={0.25}>
            <FontAwesomeIcon icon={faLandmark} />
          </Bx>
          <Bx>
            <Typo variant="body2boosted">{contact.content?.address}</Typo>
            <Typo variant="body2boosted">{contact.content?.room}</Typo>
          </Bx>
        </Bx>
      </Bx>

      <Bx pb={1}>
        <Bx display="flex">
          <Bx pr={1} width={30} mt={0.25}>
            <FontAwesomeIcon icon={faPhoneAlt} />
          </Bx>
          <Bx>
            <Typo variant="body2boosted">{contact.content?.phone}</Typo>
          </Bx>
        </Bx>
      </Bx>

      <Bx pb={1}>
        <Bx display="flex">
          <Bx pr={1} width={30} mt={0.25}>
            <FontAwesomeIcon icon={faEnvelope} />
          </Bx>
          <Bx>
            <Link to="" href={'mailto:' + contact.content?.mail} component="a">
              <Typo variant="body2boosted">{contact.content?.mail}</Typo>
            </Link>
          </Bx>
        </Bx>
      </Bx>
    </Bx>
  );
};
