import { Btn, Bx } from '@curry-group/mui-curcuma';
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router';

export const BackButton: React.FC<{ to: string }> = ({ to }) => {
  const history = useHistory();
  return (
    <Btn
      href={to}
      variant="contained"
      color="primary"
      className="print-hidden rs_skip"
      onClick={e => {
        e.preventDefault();
        history.push(to);
      }}
    >
      <Bx pr={1}>
        <FontAwesomeIcon icon={faArrowToLeft} />
      </Bx>
      Zurück zur Übersicht
    </Btn>
  );
};
