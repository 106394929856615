import { Btn, Bx } from '@curry-group/mui-curcuma';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const SaveButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <Btn variant="contained" color="primary" onClick={onClick}>
      <Bx pr={1}>
        <FontAwesomeIcon icon={faSave} />
      </Bx>
      Speichern
    </Btn>
  );
};
