import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';

export const PBListItem: React.FC<{ stringContent?: string; innerBxClassName?: string }> = ({ stringContent, innerBxClassName, children }) => {
  return (
    <li>
      <Bx className={innerBxClassName}>
        {stringContent && <Typo>{stringContent}</Typo>}
        {children}
      </Bx>
    </li>
  );
};
