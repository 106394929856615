import React from 'react';
import { Typo, Bx, Grd } from '@curry-group/mui-curcuma';
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import { useHistory } from 'react-router';

import { IFacility } from '../../../model/facility';
import { IThing } from '../../../model/thing';
import { Controls } from './controls';
import { freePlacesText } from '../../../parts';
import dayjs from 'dayjs';

export const FacilityGridItem: React.FC<{
  item?: IThing<IFacility>;
  to: string;
  showControls?: boolean;
  showClose?: boolean;
  onClose?: () => void;
  onPrev?: () => void;
  onNext?: () => void;
}> = ({ item, to, showControls, showClose, onClose, onPrev, onNext }) => {
  const history = useHistory();
  const fallbackImgUrl = '/assets/facility-fallback.jfif';
  const imageUrl = item?.content?.keyvisual?.fullPath ? '/uwao-api/core/asset/' + item.content.keyvisual.fullPath + '?tn=1&s=gallery_m' : fallbackImgUrl;
  return (
    <Card elevation={2} classes={{ root: 'CustomizedCard' }}>
      {item && (
        <CardActionArea
          TouchRippleProps={{ classes: { root: 'CustomizedTouchRipple' } }}
          href={to}
          onClick={e => {
            e.preventDefault();
            history.push(to);
          }}
          className="grid-item"
        >
          <Bx height={'92px'} p={3} pb={0} className="grid-item__headline">
            <Typo variant="h5" component="h4" color="primary.main" maxLines={2} className="CardHeadline">
              {item.content?.name}
            </Typo>
          </Bx>
          <Bx p={3} display="flex" justifyContent="space-between" style={{ paddingTop: 0 }} className="grid-item__distance">
            <Grd container>
              <Grd item xs={item.content?.distance ? 8 : 12}>
                <Typo variant="body1" color="textSecondary" maxLines={1}>
                  {item.content?.zipCode + ', ' + item.content?.location}
                </Typo>
              </Grd>
              {!!item.content?.distance && (
                <Grd item xs={4}>
                  <Typo variant="body1" color="textSecondary" align="right">
                    {((item.content?.distance ?? 0) / 1000).toFixed(2)} km
                  </Typo>
                </Grd>
              )}
            </Grd>
          </Bx>

          <Bx position="relative">
            <CardMedia title={'Titelbild der Einrichtung: ' + item.content?.name} image={imageUrl} style={{ height: '192px', width: '100%' }} />
            {item?.content?.placesFree !== undefined && item.content?.placesFree > 0 && (
              <Bx position="absolute" width="100%" bottom={-7} display="flex" justifyContent="center">
                <Bx
                  color="primary.dark"
                  bgcolor="quaternary.light"
                  borderRadius="borderRadius"
                  py={0.25}
                  px={1.5}
                  fontWeight="bold"
                  fontSize="body2boosted"
                  display="inline-block"
                  className="OverflowBadge"
                  boxShadow={3}
                >
                  Freie Plätze verfügbar
                </Bx>
              </Bx>
            )}
          </Bx>

          <CardContent className="grid-item__content">
            <Bx px={1} style={{ paddingTop: 0 }}>
              <Bx mb={0.25}>
                <Typo variant="subtitle2boosted" fontWeight="bold" color="textSecondary" textTransform="uppercase">
                  Plätze
                </Typo>
              </Bx>
              <Typo>{freePlacesText(item.content)}</Typo>
            </Bx>
            <Bx px={1} mt={2}>
              <Bx mb={0.25}>
                <Typo variant="subtitle2boosted" fontWeight="bold" color="textSecondary" textTransform="uppercase">
                  Telefon
                </Typo>
              </Bx>
              <Typo>{item.content?.telephone}</Typo>
            </Bx>
            <Bx px={1} mt={2} height={81}>
              <Bx mb={0.25}>
                <Typo variant="subtitle2boosted" fontWeight="bold" color="textSecondary" textTransform="uppercase">
                  E-Mail
                </Typo>
              </Bx>
              <Typo maxLines={2}>{item.content?.publicMail}</Typo>
            </Bx>
            <Bx px={1} mt={2} height={81}>
              <Bx mb={0.25}>
                <Typo variant="subtitle2boosted" fontWeight="bold" color="textSecondary" textTransform="uppercase">
                  Zuletzt aktualisiert
                </Typo>
              </Bx>
              <Typo maxLines={2}>{item?.content?.lastUpdate ? dayjs(item.content.lastUpdate * 1000).format('DD.MM.YYYY') : 'Keine Angabe'}</Typo>
            </Bx>
          </CardContent>
        </CardActionArea>
      )}

      {(showControls || showClose) && <Controls showClose={showClose} showControls={showControls} onClose={onClose} onNext={onNext} onPrev={onPrev} />}
    </Card>
  );
};
