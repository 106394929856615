import React from 'react';

import { IFacility } from '../../../model/facility';
import { IThing } from '../../../model/thing';
import { AssistedLiving, SemiStationary, Stationary } from '../../../model/facilitity-care-types';

import { EditStationary } from './stationary';
import { EditSemiStationary } from './semi-stationary';
import { EditAssistedLiving } from './assisted-living';
import { EditWrapper } from './wrapper';

export const FacilityEditItem: React.FC<{
  item?: IThing<IFacility>;
  setItem?: (item: IThing<IFacility>) => void;
}> = ({ item, setItem }) => {
  if (!item?.content) {
    return null;
  }

  const content = item?.content;
  let headline;
  switch (content.facilityCareType?._id) {
    case SemiStationary:
    case Stationary:
      headline = 'Freie Pflegeplätze';
      break;
    case AssistedLiving:
      headline = 'Freie Apartements';
      break;
  }
  return (
    <EditWrapper headline={headline} facilityCareType={item.content.facilityCareType?.content?.name}>
      {content.facilityCareType?._id === Stationary && <EditStationary item={item} setItem={setItem} />}
      {content.facilityCareType?._id === SemiStationary && <EditSemiStationary item={item} setItem={setItem} />}
      {content.facilityCareType?._id === AssistedLiving && <EditAssistedLiving item={item} setItem={setItem} />}
    </EditWrapper>
  );
};
