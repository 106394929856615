import { Bx, CheckboxControl, Collapse } from '@curry-group/mui-curcuma';
import React, { useEffect, useState } from 'react';
import { IReferenceItem } from '../../../model/facility';
import { CollapsibleFilterHead } from '../collapsible-filter-head';

export const SemiStationaryFilter: React.FC<{ careTypes: number[]; setCareTypes: (value: number[]) => void; collapsible?: boolean }> = ({
  careTypes,
  setCareTypes,
  collapsible
}) => {
  const [types, setTypes] = useState<IReferenceItem[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      const response = await fetch('/uwao-api/care-erlangen/filteritems/caretypes');
      const json: IReferenceItem[] = await response.json();
      setTypes(json);
    };
    load();
  }, []);

  const RegularFilters = () => {
    return (
      <>
        {types.map((s, idx) => {
          return (
            <Bx key={s._id} mb={idx !== types.length - 1 ? 0.5 : undefined} width="100%">
              <CheckboxControl
                label={s.content?.name}
                checked={careTypes.includes(s.sequentialNumber ?? 0)}
                onChange={(e, v) => {
                  if (s.sequentialNumber !== undefined) {
                    if (v) {
                      setCareTypes([...careTypes, s.sequentialNumber]);
                    } else {
                      setCareTypes(careTypes.filter(ct => ct !== s.sequentialNumber));
                    }
                  }
                }}
              />
            </Bx>
          );
        })}
      </>
    );
  };

  return (
    <>
      {collapsible ? (
        <>
          <CollapsibleFilterHead title="Art der Betreuung" expanded={expanded} onClick={() => setExpanded(!expanded)} />
          <Collapse expanded={expanded}>
            <Bx py={1.5} px={0.25}>
              <RegularFilters />
            </Bx>
          </Collapse>
        </>
      ) : (
        <RegularFilters />
      )}
    </>
  );
};
