import { Grd, Bx, CheckboxControl, Typo } from "@curry-group/mui-curcuma";
import React from "react";
import { IFacility } from "../../../../model/facility";
import { IThing } from "../../../../model/thing";
import { EditControlFreeText } from "../controls/free-text";
import { EditControlNumber } from "../controls/number";

export const EditAssistedLiving: React.FC<{
  item?: IThing<IFacility>;
  setItem?: (item: IThing<IFacility>) => void;
}> = ({ item, setItem }) => {
  return (
    <Grd container spacing={3}>
      <Grd item xs={12}>
        <EditControlNumber
          value={item?.content?.apartmentsFree}
          onValueChange={(value) => {
            setItem?.({
              ...item,
              content: {
                ...item?.content,
                apartmentsFree: value === "" ? undefined : value,
              },
            });
          }}
        />
      </Grd>
      <Grd item xs={12}>
        <Bx>
          <Bx mb={1}>
            <Typo variant="h5" color="primary">
              Appartementgrößen
            </Typo>
          </Bx>
          {item?.content?.apartmentsSubD?.map((as, idx) => {
            return (
              <Bx key={idx}>
                <CheckboxControl
                  label={
                    item.content?.apartmentSizesResolved?.[
                      as.apartmentSize ?? ""
                    ].content?.name
                  }
                  checked={as.apartmentAvailable ?? false}
                  onChange={(e) => {
                    if (item.content?.apartmentsSubD?.[idx]) {
                      const changed = { ...item.content.apartmentsSubD[idx] };
                      changed.apartmentAvailable = !!!as.apartmentAvailable;
                      const newArray = [...item.content.apartmentsSubD];
                      newArray[idx] = changed;
                      setItem?.({
                        ...item,
                        content: { ...item.content, apartmentsSubD: newArray },
                      });
                    }
                  }}
                />
              </Bx>
            );
          })}
        </Bx>
      </Grd>
      <Grd item xs={12}>
        <Bx mb={1}>
          <Typo variant="h5" color="primary">
            <label htmlFor="field-freetext-assisted">Hinweise</label>
          </Typo>
        </Bx>
        <Bx>
          <EditControlFreeText
            id="field-freetext-assisted"
            value={item?.content?.description ?? ""}
            onValueChange={(value) => {
              setItem?.({
                ...item,
                content: { ...item?.content, description: value },
              });
            }}
          />
        </Bx>
      </Grd>
    </Grd>
  );
};
