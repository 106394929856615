import { Grd, Bx, Divider, Typo } from '@curry-group/mui-curcuma';
import { Card, CardActionArea, CardContent } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router';
import { INavigation, IPageContent } from '../../../model/page';

export const NewsFeedComponent: React.FC<{
  className?: string;
  pageContent?: IPageContent;
  navigation?: INavigation;
}> = ({ className, pageContent, navigation }) => {
  const history = useHistory();
  return (
    <Bx mt={3}>
      <Grd container className={className} spacing={3}>
        {pageContent?.newsFeedResolved?.map(nf => {
          let href = '/startseite/' + nf.seo?.alias;
          for (let category of navigation?.categories || []) {
            let doBreak = false;
            for (let page of category.pages || []) {
              if (page.pageResolved._id === nf._id) {
                href = '/startseite/' + category.pageResolved.seo?.alias + '/' + nf.seo?.alias;
                doBreak = true;
                break;
              }
            }
            if (doBreak) break;
          }
          let cardBody = nf.seo?.description;
          if (!cardBody) {
            for (let item of nf.content?.pageContent || []) {
              if (!item.newsFeed) {
                cardBody = item.content;
                break;
              }
            }
          }
          if (!cardBody) {
            cardBody = nf.content?.content;
          }
          let imageBody = nf.seo?.image;
          if (!imageBody) {
            for (let item of nf.content?.pageContent || []) {
              if (!item.newsFeed && item.image) {
                imageBody = item.image;
                break;
              }
            }
          }
          return (
            <Grd item xs={12} sm={6} md={6} lg={4} key={nf._id}>
              <Card elevation={2}>
                <CardActionArea
                  TouchRippleProps={{ classes: { root: 'CustomizedTouchRipple' } }}
                  href={href}
                  onClick={e => {
                    e.preventDefault();
                    history.push(href);
                  }}
                  className="news-feed"
                >
                  <CardContent>
                    <Bx p={1} className="news-feed__box">
                      <Bx className="news-feed__headline" height={46}>
                        <Typo variant="h5" component="h3" maxLines={2} className="CardHeadline">
                          {nf.content?.titleShort || nf.content?.title}
                        </Typo>
                        {/* <Typo variant="body2" fontWeight={700} maxLines={2}>
                          {nf.content?.titleShort || nf.content?.title}
                        </Typo> */}
                      </Bx>
                      <Bx className="news-feed__content" mt={1} height={276}>
                        <Typo variant="body1" maxLines={imageBody ? 3 : 10}>
                          {cardBody?.replaceAll(/<[^>]*>/gim, '')}
                        </Typo>
                        {imageBody && (
                          <Bx mt={cardBody ? 1 : undefined} width="100%" height={176}>
                            <img
                              src={'/uwao-api/core/assetbyid?id=' + imageBody}
                              alt={nf.content?.previewImageAlt || 'Symbolbild der Nachricht'}
                              style={{ display: 'block', width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                          </Bx>
                        )}
                      </Bx>
                      <Bx className="news-feed__divider" mt={2}>
                        <Divider />
                      </Bx>
                      <Bx className="news-feed__date" display="flex" justifyContent="flex-end" mt={1}>
                        <Typo variant="subtitle2">{dayjs(nf.modifiedAt || nf.createdAt).format('DD. MMMM YYYY')}</Typo>
                      </Bx>
                    </Bx>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grd>
          );
        })}
      </Grd>
    </Bx>
  );
};
