import React from "react";
import { IThing } from "../../../../model/thing";
import { IFacility } from "../../../../model/facility";
import { BoxedUnsortedList } from "../../../boxed-unsorted-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "@material-ui/core";

export const FacilityItemOffers: React.FC<{ item?: IThing<IFacility> }> = ({
  item,
}) => {
  const offersFree = (item?.content?.offersFree || []).map((o) => {
    return {
      _id: o._id,
      name: o.content?.name ?? "",
      data: o.content?.name ? <>{o.content?.name}</> : "",
      free: 1,
    };
  });
  const offersNonFree = (item?.content?.offersNonFree || []).map((o) => {
    return {
      _id: o._id,
      name: o.content?.name ?? "",
      data: o.content?.name ? (
        <span>
          <Tooltip arrow title="Kostenpflichtig">
            <span
              aria-label="Kostenpflichtig"
              className="border-1 text-color-primary-main border-radius-borderRadius d-inline-flex mr-0-5 text-variant-subtitle2 align-items-center justify-content-center position-relative"
              style={{ width: 20, height: 20, top: -2 }}
            >
              <span className="ml-m0-25">
                <FontAwesomeIcon icon={faEuroSign} />
              </span>
            </span>
          </Tooltip>
          {o.content?.name}
        </span>
      ) : (
        ""
      ),
      free: 0,
    };
  });
  const offersCombined = offersFree
    .concat(offersNonFree)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((el) => el.data);

  return (
    <>
      {offersCombined.length > 0 && (
        <BoxedUnsortedList
          asGrid
          title="Ausstattung und Angebote"
          items={offersCombined}
        />
      )}
    </>
  );
};
