import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faLongArrowLeft, faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { IThing } from '../../../../model/thing';
import { IFacility } from '../../../../model/facility';
import { CardMedia } from '@material-ui/core';
import { Bx, Anohana, ImageGalleryDetailView } from '@curry-group/mui-curcuma';

type TImageProps = {
  image: string;
  alt: string;
  title: string;
  downloadImage: string;
};

type TImageGalleryImagesProps = {
  thumbnail: TImageProps;
  fullImage: TImageProps;
  index: number;
};

export const FacilityItemDetailGallery: React.FC<{ item?: IThing<IFacility> }> = ({ item }) => {
  const [imageIdx, setImageIdx] = useState(0);
  const [galleryOpen, setGalleryOpen] = React.useState(false);
  const fallbackImgUrl = '/assets/facility-fallback.jfif';
  const imageUrl = item?.content?.keyvisual?.fullPath ? '/uwao-api/core/asset/' + item.content.keyvisual.fullPath + '?tn=1&s=gallery_m' : fallbackImgUrl;
  let images: { title: string; imgSrc: string; seo?: { title?: string; alias?: string; alt?: string } }[] = [
    { title: 'Titelbild der Einrichtung: ' + item?.content?.name, imgSrc: imageUrl }
  ];
  const gallery = item?.content?.gallery || [];
  images = [
    ...images,
    ...gallery.map(g => {
      return { seo: g.seo, title: g.displayName, imgSrc: '/uwao-api/core/asset/' + g.fullPath + '?tn=1&s=gallery_m' };
    })
  ];
  let galleryImages: TImageGalleryImagesProps[] = [];

  images &&
    images.length &&
    images.forEach((img, index: number) => {
      galleryImages.push({
        index: index + 1,
        thumbnail: {
          image: img.imgSrc,
          alt: img.seo?.alt || img.seo?.title || img.title,
          title: img.title,
          downloadImage: img.imgSrc
        },
        fullImage: {
          image: img.imgSrc.replace('?tn=1&s=gallery_m', ''),
          alt: img.seo?.alt || img.seo?.title || img.title,
          title: img.title,
          downloadImage: img.imgSrc.replace('?tn=1&s=gallery_m', '')
        }
      });
    });
  const increment = () => {
    if (imageIdx < images.length - 1) {
      setImageIdx(imageIdx + 1);
    } else {
      setImageIdx(0);
    }
  };
  const decrement = () => {
    if (imageIdx > 0) {
      setImageIdx(imageIdx - 1);
    } else {
      setImageIdx(images.length - 1);
    }
  };
  return (
    <>
      <CardMedia
        title={images[imageIdx].title}
        image={images[imageIdx].imgSrc}
        style={{ height: '100%', width: '100%', borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
        className="detail-gallery"
      >
        {images.length > 1 && (
          <Bx display="flex" justifyContent="space-between" alignItems="flex-end" p={2} height="100%">
            <Bx
              component="button"
              bgcolor="common.white"
              hoverBgcolor="primary.main"
              focusBgcolor="primary.dark"
              color="text.primary"
              hoverColor="primary.contrastText"
              focusColor="primary.contrastText"
              focusRing={3}
              focusRingColor="background.paper"
              focusRingOpacity={70}
              outline="none"
              transition="colors"
              border={0}
              width={48}
              height={48}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="borderRadius2"
              fontSize="h3"
              onClick={decrement}
              style={{ cursor: 'pointer' }}
              aria-label="Galerie zurück blättern"
            >
              <FontAwesomeIcon icon={faLongArrowLeft} />
            </Bx>
            {/* <IconBtn variant="contained" color="primary" shape="rounded" onClick={increment}>
            <FontAwesomeIcon icon={faChevronRight} />
          </IconBtn> */}
            <Bx
              component="button"
              bgcolor="common.white"
              hoverBgcolor="primary.main"
              focusBgcolor="primary.dark"
              color="text.primary"
              hoverColor="primary.contrastText"
              focusColor="primary.contrastText"
              focusRing={3}
              focusRingColor="background.paper"
              focusRingOpacity={70}
              outline="none"
              transition="colors"
              border={0}
              height={48}
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="borderRadius2"
              fontSize="body2"
              fontWeight="bold"
              onClick={() => setGalleryOpen(true)}
              style={{ cursor: 'pointer' }}
              aria-label="Vollbild-Ansicht der Galerie öffnen"
            >
              <Bx mr={1} fontSize="body1">
                <FontAwesomeIcon icon={faImages} />
              </Bx>
              {images.length} Fotos in der Galerie
            </Bx>

            <Bx
              component="button"
              bgcolor="common.white"
              hoverBgcolor="primary.main"
              focusBgcolor="primary.dark"
              color="text.primary"
              hoverColor="primary.contrastText"
              focusColor="primary.contrastText"
              focusRing={3}
              focusRingColor="background.paper"
              focusRingOpacity={70}
              outline="none"
              transition="colors"
              border={0}
              width={48}
              height={48}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="borderRadius2"
              fontSize="h3"
              onClick={increment}
              style={{ cursor: 'pointer' }}
              aria-label="Galerie vorwärts blättern"
            >
              <FontAwesomeIcon icon={faLongArrowRight} />
            </Bx>
          </Bx>
        )}
      </CardMedia>
      <Anohana fullScreen transition="zoom" open={galleryOpen} onClose={() => setGalleryOpen(false)}>
        <ImageGalleryDetailView
          allowDownload={false}
          showTitle
          showPaginationCount
          onClose={() => setGalleryOpen(false)}
          images={{ current: galleryImages[imageIdx], images: galleryImages, next: galleryImages[imageIdx + 1], prev: galleryImages[imageIdx - 1] }}
        />
      </Anohana>
    </>
  );
};
