import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';

export const EditWrapper: React.FC<{ headline?: string; facilityCareType?: string }> = ({ children, headline, facilityCareType }) => {
  return (
    <Bx p={2}>
      {facilityCareType && (
        <Bx mb={2}>
          <Typo variant="h5" color="textSecondary">
            {facilityCareType}
          </Typo>
        </Bx>
      )}
      {headline && (
        <Bx mb={children ? 4 : undefined}>
          <Typo variant="h3">{headline}</Typo>
        </Bx>
      )}
      {children && <Bx>{children}</Bx>}
    </Bx>
  );
};
