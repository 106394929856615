import { Typo } from '@curry-group/mui-curcuma';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { AssistedLiving, SemiStationary, Stationary } from '../../../../model/facilitity-care-types';
import { IFacility } from '../../../../model/facility';
import { IThing } from '../../../../model/thing';
import { CareInfoAssistedLiving } from './assisted-living';
import { CareInfoSemiStationary } from './semi-stationary';
import { CareInfoStationary } from './stationary';

export const CheckMarkIcon = () => (
  <Typo color="primary" component="span">
    <FontAwesomeIcon icon={faCheck} />
  </Typo>
);

export const CareInfo: React.FC<{ item?: IThing<IFacility> }> = ({ item }) => {
  return (
    <>
      {item?.content?.facilityCareType?._id === Stationary && <CareInfoStationary item={item} />}
      {item?.content?.facilityCareType?._id === SemiStationary && <CareInfoSemiStationary item={item} />}
      {item?.content?.facilityCareType?._id === AssistedLiving && <CareInfoAssistedLiving item={item} />}
    </>
  );
};
