import { Bx, Typo, UnsortedList } from "@curry-group/mui-curcuma";
import React from "react";

import { IFacility } from "../../../../../model/facility";
import { IThing } from "../../../../../model/thing";
import { CheckMarkIcon } from "..";

export const CareInfoStationary: React.FC<{ item?: IThing<IFacility> }> = ({
  item,
}) => {
  const roomTypes = (item?.content?.roomTypesSubD || []).filter(
    (rt) => rt.roomAvailable
  );
  const specialCareOffersAvailable: string[] = [];
  if (item?.content?.specialCareOffersSubD) {
    item?.content?.specialCareOffersSubD.forEach((el) => {
      if (el?.specialCareOfferAvailable) {
        specialCareOffersAvailable.push(el.specialCareOffer as string);
      }
      return null;
    });
  }

  const specialCareOffers: string[] = [];
  if (item && item.content?.specialCareOffersResolved) {
    Object.entries(item?.content?.specialCareOffersResolved).forEach((el) => {
      if (
        el[1].content?.name &&
        specialCareOffersAvailable.indexOf(el[1]._id) !== -1
      ) {
        specialCareOffers.push(el[1].content.name);
      }
      return null;
    });
  }
  // const carePlaceTypes = (item?.content?.carePlaceTypesSubD || []).filter(
  //   (cpt) => cpt.carePlaceTypeFree
  // );

  let freeCareTypes: any[] = [];
  item?.content?.carePlaceTypesSubD?.forEach((cpt, idx) => {
    let carePlaceType =
      item.content?.carePlaceTypesResolved?.[cpt.carePlaceType ?? ""];
    if (cpt.carePlaceTypeFree !== undefined && cpt.carePlaceTypeFree) {
      if (carePlaceType?.content?.name) {
        freeCareTypes.push({
          _id: carePlaceType._id,
          label: carePlaceType.content.name,
          count: cpt.carePlaceTypeFree.toString(),
          indent: (cpt as any).carePlaceTypeIgnoreCount,
        });
      }
    }
  });

  let freeRoomTypes: any[] = [];
  [...roomTypes, ...specialCareOffers].forEach((rt: any, idx) => {
    const roomTypeResolved =
      item?.content?.roomTypesResolved?.[rt.roomType ?? ""]?.content || {};
    const male = rt.roomMale && rt.roomAvailableMale;
    const female = rt.roomFemale && rt.roomAvailableFemale;

    let genderLabel = "";
    if (male && !female) {
      genderLabel = "(männlich)";
    } else if (!male && female) {
      genderLabel = "(weiblich)";
    }
    freeRoomTypes.push({
      children: (
        <>
          {typeof rt === "string" ? (
            rt
          ) : (
            <>
              {roomTypeResolved.name} {genderLabel}
            </>
          )}
        </>
      ),
      icon: <CheckMarkIcon />,
    });
  });

  return (
    <>
      <Bx mb={2}>
        <Typo variant="h3" component="h5" color="textSecondary">
          <Typo color="primary" component="span" variant="inherit">
            {item?.content?.placesFree || 0}
          </Typo>{" "}
          von{" "}
          <Typo color="primary" component="span" variant="inherit">
            {item?.content?.placesTotal || 0}
          </Typo>{" "}
          Plätzen{" "}
          <Typo
            color="primary"
            component="span"
            variant="inherit"
            style={{ textDecoration: "underline" }}
          >
            frei
          </Typo>
        </Typo>
      </Bx>
      {freeCareTypes.length > 0 && (
        <Bx mb={4}>
          <Typo color="primary" component="strong" fontWeight="bold">
            Davon möglich:
          </Typo>
          <Bx mt={2}>
            <Typo component="div">
              {freeCareTypes.map((item, idx) => (
                <Bx
                  key={item._id + "_" + idx}
                  component="div"
                  display="flex"
                  justifyContent="space-between"
                  ml={item.indent ? 2.5 : undefined}
                  mb={2}
                  mt={item.indent ? -1 : undefined}
                >
                  <Bx component="span">{item.label}</Bx>
                  {/* {!item.indent && ( */}
                  <Bx
                    component="strong"
                    color={!item.indent ? "primary.main" : "text.secondary"}
                    fontWeight="bold"
                  >
                    {item.count}
                  </Bx>
                  {/* )} */}
                </Bx>
              ))}
              {/* <UnsortedList spacing={2} iconSpacing={1.5} items={freeCareTypes} /> */}
            </Typo>
          </Bx>
        </Bx>
      )}
      {freeRoomTypes.length > 0 && (
        <>
          <Bx mb={2}>
            <Typo color="primary" component="strong" fontWeight="bold">
              Zusätzliche Informationen zu den freien Plätzen:
            </Typo>
          </Bx>
          <Typo component="div">
            <UnsortedList spacing={2} iconSpacing={1.5} items={freeRoomTypes} />
          </Typo>
        </>
      )}
      {item?.content?.description && (
        <>
          <Bx mt={4} mb={2}>
            <Typo color="primary" component="strong" fontWeight="bold">
              Hinweise:
            </Typo>
          </Bx>
          <Bx>
            <Typo
              dangerouslySetInnerHTML={{
                __html: (item?.content?.description ?? "")
                  .replaceAll("<p>", "")
                  .replaceAll("</p>", ""),
              }}
            />
          </Bx>
        </>
      )}
    </>
  );
};
