import { IFacility } from "../model/facility";
import { INavigation } from "../model/page";

export const freePlacesText = (content?: IFacility) => {
  return (
    (content?.placesFree || "0") +
    " von " +
    (content?.placesTotal || "0") +
    " frei"
  );
  // let places = 'Keine Angabe';
  // if (content?.placesFree !== undefined && content?.placesTotal !== undefined) {
  //   places = content.placesFree + ' von ' + content.placesTotal + ' frei';
  // } else if (content?.placesTotal !== undefined) {
  //   places = content.placesTotal + ' gesamt';
  // } else if (content?.placesFree !== undefined) {
  //   places = content.placesFree + ' frei';
  // }
  // return places;
};

export const defaultCoords: [number, number] = [49.589674, 11.011961];

export const getNavLinks: (navigation?: INavigation) => {
  [alias: string]: string;
} = (navigation) => {
  const result: { [alias: string]: string } = {};
  navigation?.categories?.forEach((cat) => {
    if (cat.pageResolved.seo?.alias)
      result[cat.pageResolved.seo.alias] =
        "/startseite/" + cat.pageResolved.seo.alias;
    cat.pages?.forEach((page) => {
      if (page.pageResolved.seo?.alias)
        result[page.pageResolved.seo.alias] =
          "/startseite/" +
          cat.pageResolved.seo?.alias +
          "/" +
          page.pageResolved.seo.alias;
      page.subpages?.forEach((subpage) => {
        if (subpage.pageResolved.seo?.alias)
          result[subpage.pageResolved.seo.alias] =
            "/startseite/" +
            cat.pageResolved.seo?.alias +
            "/" +
            page.pageResolved.seo?.alias +
            "/" +
            subpage.pageResolved.seo.alias;
      });
    });
  });
  return result;
};

export const resolveLinks: (
  content?: string,
  navigation?: INavigation
) => string = (content, navigation) => {
  const navLinks = getNavLinks(navigation);
  return (content || "").replace(/<a href="\/#page![^>]+">/gim, (content) => {
    let alias = content.split("!")[1]?.split('"')[0];
    const link = navLinks[alias];
    return `<a href="${link || "/404"}">`;
  });
};

export const cleanUpPhoneNumber = (str: string) => {
  return str.replace(/\//g, "").replace(/-/g, "").replace(/\s/g, "");
};

export const getCleanUrl = (str: string) => {
  return "https://" + str.replace("http://", "").replace("https://", "");
};

declare var window: any;

export const trackPageView = (title: string, path: string) => {
  if ("_paq" in window && window._paq !== undefined && window.__matomoEnabled) {
    var _paq = window._paq;
    if (!window.__initialTrack) {
      window.__initialTrack = true;
    }
    _paq.push(["setDocumentTitle", title]);
    _paq.push(["setCustomUrl", path]);
    _paq.push(["trackPageView"]);
  }
};
