import React from 'react';
import { Bx } from '@curry-group/mui-curcuma';
import { Icon } from 'leaflet';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { IThing } from '../../../../model/thing';
import { IFacility } from '../../../../model/facility';
import { defaultCoords } from '../../../../parts';

const mapTenant = process.env.REACT_APP_MAP_TENANT || 'pflegeplatzboerse-7c4d6e49';

const useStyles = makeStyles({
  marker: {
    width: 80,
    height: 80,
    borderRadius: 100
  }
});

export const LocationMap: React.FC<{ item?: IThing<IFacility> }> = ({ item }) => {
  const styles = useStyles();
  const latLon: [number, number] = [item?.content?.lat || defaultCoords[0], item?.content?.lon || defaultCoords[1]];
  const fallbackImgUrl = '/assets/facility-fallback.jfif';
  const imageUrl = item?.content?.keyvisual?.fullPath ? '/uwao-api/core/asset/' + item.content.keyvisual.fullPath + '?tn=1&s=gallery_m' : fallbackImgUrl;
  const leafletMarker = new Icon({
    iconUrl: imageUrl,
    iconSize: [55, 55],
    iconAnchor: [27, 75],
    shadowUrl: '/assets/leaflet/images/marker-shadow.svg',
    // shadowSize: [80, 80],
    shadowAnchor: [40, 80],
    className: styles.marker
  });
  return (
    <Bx className="print-hidden rs_skip" height="100%">
      <Bx width="100%" height="100%">
        <MapContainer center={latLon} zoom={14} scrollWheelZoom={false} style={{ height: '100%' }}>
          <TileLayer url={'https://maps.omniscale.net/v2/' + mapTenant + '/style.default/{z}/{x}/{y}.png'} />
          <Marker position={latLon} icon={leafletMarker} />
        </MapContainer>
      </Bx>
    </Bx>
  );
};
