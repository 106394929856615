import {
  Ctn,
  Grd,
  Bx,
  Typo,
  ListWrapper,
  ExtendedListItem,
  useBreakpoints,
  Btn,
  Html2View,
} from "@curry-group/mui-curcuma";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import clsx from "clsx";

import { IThing } from "../../model/thing";
import { IPage, INavigation } from "../../model/page";
import { ContactCard } from "./contact-card";
import { resolveLinks } from "../../parts";
import { ReadSpeakerButton } from "../readspeaker";
import { SearchBoxes } from "../search-boxes";
import { NewsFeedComponent } from "./news-feed";
import { PrintButton } from "../print-button";

export const PageView: React.FC<{
  navigation?: INavigation;
  centered?: boolean;
}> = ({ navigation, centered }) => {
  const theme = useTheme();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const params = useParams<any>();
  const [page, setPage] = useState(undefined as IThing<IPage> | undefined);
  const pageContents = page?.content?.pageContent || [];
  const widgets = page?.content?.widgets || [];
  const localNav = navigation?.categories?.find(
    (cat) => cat.pageResolved.seo?.alias === params.category
  ) ?? { pages: [], pageResolved: {} };
  const contacts = page?.content?.contacts ?? [];
  const linkList = localNav && localNav.pages && localNav.pages.length > 1;
  const rsClassSingle = "readspeaker-readable-" + page?._id;
  useEffect(() => {
    window.scrollTo(0, 0);

    const load = async () => {
      const alias =
        params.subpage ?? params.page ?? params.category ?? "startseite";
      const response = await fetch(
        "/uwao-api/care-erlangen/page/page/" + alias
      );
      const json = await response.json();
      setPage(json);
    };
    load();
  }, [params.category, params.page, params.subpage, navigation]);
  return (
    <Bx
      pt={10}
      pb={10}
      display="flex"
      flexDirection="column"
      className="print-no-padding-top-bottom"
    >
      <Helmet>
        <title>
          {(page?.content?.titleShort || page?.content?.title) +
            " - Pflegeplatzbörse"}
        </title>
      </Helmet>
      <Ctn maxWidth="lg" disableGutters={false} style={{ overflowX: "clip" }}>
        {widgets.length > 0 && centered && <SearchBoxes widgets={widgets} />}
        <Bx>
          <Grd container spacing={8}>
            <Grd
              item
              xs={12}
              md={centered || !linkList ? 12 : 8}
              lg={centered || !linkList ? 12 : 9}
              style={{ order: breakpoints.smDown ? 1 : 2 }}
            >
              {pageContents.length > 0 && (
                <Bx
                  mt={centered ? 14 : undefined}
                  className="print-no-margin-top"
                >
                  {pageContents.map((pc, idx) => {
                    const rsClassName =
                      "readspeaker-readable-" + page?._id + "-" + idx;
                    return (
                      <Bx
                        key={idx}
                        mt={idx !== 0 ? 10 : undefined}
                        className="print-no-margin-top"
                        component="section"
                      >
                        {pc.title && (
                          <Bx display="flex" justifyContent="space-between">
                            <Typo
                              variant="h1"
                              component="h2"
                              className={rsClassName}
                            >
                              {pc.title}
                            </Typo>
                            {idx === 0 && <PrintButton />}
                          </Bx>
                        )}
                        <Bx mt={1}>
                          <ReadSpeakerButton
                            readClass={rsClassName}
                            identifier={page?._id}
                          />
                        </Bx>
                        {pc.newsFeed && (
                          <NewsFeedComponent
                            className={rsClassName}
                            pageContent={pc}
                            navigation={navigation}
                          />
                        )}
                        {!pc.newsFeed && !pc.twoColumned && pc.content && (
                          <Html2View>
                            {resolveLinks(pc.content, navigation)}
                          </Html2View>
                        )}
                        {!pc.newsFeed && pc.twoColumned && (
                          <Bx mt={pc.title ? 2 : undefined}>
                            <Grd container spacing={3}>
                              {!pc.imageLeft && (
                                <>
                                  <Grd
                                    item
                                    xs={12}
                                    sm={8}
                                    md={6}
                                    className={rsClassName}
                                  >
                                    {pc.content && (
                                      <Html2View>
                                        {resolveLinks(pc.content, navigation)}
                                      </Html2View>
                                    )}
                                  </Grd>
                                  <Grd item xs={12} sm={4} md={6}>
                                    {pc.image && (
                                      <img
                                        alt={
                                          pc.imageAlt ||
                                          "Symbolbild zu diesem Abschnitt"
                                        }
                                        src={
                                          "/uwao-api/core/assetbyid?id=" +
                                          pc.image
                                        }
                                        style={{ width: "100%" }}
                                      />
                                    )}
                                  </Grd>
                                </>
                              )}
                              {pc.imageLeft && (
                                <>
                                  <Grd item xs={12} sm={4} md={6}>
                                    {pc.image && (
                                      <img
                                        alt={
                                          pc.imageAlt ||
                                          "Symbolbild zu diesem Abschnitt"
                                        }
                                        src={
                                          "/uwao-api/core/assetbyid?id=" +
                                          pc.image
                                        }
                                        style={{ width: "100%" }}
                                      />
                                    )}
                                  </Grd>
                                  <Grd
                                    item
                                    xs={12}
                                    sm={8}
                                    md={6}
                                    className={rsClassName}
                                  >
                                    {pc.content && (
                                      <Html2View>
                                        {resolveLinks(pc.content, navigation)}
                                      </Html2View>
                                    )}
                                  </Grd>
                                </>
                              )}
                            </Grd>
                          </Bx>
                        )}
                      </Bx>
                    );
                  })}
                </Bx>
              )}
              {!(pageContents.length > 0) && (
                <Bx
                  mt={centered ? 14 : undefined}
                  className={clsx("print-no-margin-top", rsClassSingle)}
                >
                  <Bx display="flex" justifyContent="space-between">
                    <Typo variant="h1" component="h2">
                      {page?.content?.title}
                    </Typo>
                    <PrintButton />
                  </Bx>

                  <Bx mt={1}>
                    <ReadSpeakerButton
                      readClass={rsClassSingle}
                      identifier={page?._id}
                    />
                  </Bx>
                  {page?.content?.content && (
                    <Html2View>
                      {resolveLinks(page?.content?.content, navigation)}
                    </Html2View>
                  )}
                </Bx>
              )}
            </Grd>
            {!centered && linkList && (
              <Grd
                item
                xs={12}
                md={4}
                lg={3}
                style={{ order: breakpoints.smDown ? 2 : 1 }}
                className="print-hidden"
              >
                <Bx component="aside">
                  <Bx position="sticky" top={116}>
                    {linkList && (
                      <Bx
                        style={{
                          border: "1px solid #bfbfbf",
                          borderRadius: theme.shape.borderRadius,
                          borderBottom: 0,
                          overflow: "hidden",
                        }}
                      >
                        <ListWrapper
                          component="nav"
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: 4,
                            overflow: "hidden",
                          }}
                        >
                          <ExtendedListItem
                            p={1}
                            to={
                              "/startseite/" + localNav.pageResolved.seo?.alias
                            }
                            active={
                              !params.subpage &&
                              !params.page &&
                              params.category ===
                                localNav.pageResolved.seo?.alias
                            }
                            activeColor="#fff"
                            activeColorHover="#fff"
                            hoverColor="#fff"
                          >
                            {localNav.pageResolved.content?.titleShort ||
                              localNav.pageResolved.content?.title}
                          </ExtendedListItem>
                          {localNav.pages.map((p, idx) => (
                            <ExtendedListItem
                              key={idx}
                              p={1}
                              to={
                                "/startseite/" +
                                localNav.pageResolved.seo?.alias +
                                "/" +
                                p.pageResolved.seo?.alias
                              }
                              active={
                                !params.subpage &&
                                params.page === p.pageResolved.seo?.alias
                              }
                              activeColor="#fff"
                              activeColorHover="#fff"
                              hoverColor="#fff"
                            >
                              {p.pageResolved.content?.titleShort ||
                                p.pageResolved.content?.title}
                            </ExtendedListItem>
                          ))}
                        </ListWrapper>
                      </Bx>
                    )}
                    {widgets.length > 0 &&
                      widgets.map((widget, idx) => (
                        <Bx
                          key={idx}
                          style={{
                            border: "1px solid #bfbfbf",
                            borderRadius: theme.shape.borderRadius,
                          }}
                          mt={linkList ? 2 : undefined}
                          p={2}
                        >
                          {widget.title && (
                            <Typo variant="h5" component="h5">
                              {widget.title}
                            </Typo>
                          )}
                          {widget.description && (
                            <Bx mt={widget.title ? 2 : undefined}>
                              <Typo>{widget.description}</Typo>
                            </Bx>
                          )}
                          <Bx
                            mt={
                              widget.title || widget.description ? 2 : undefined
                            }
                          >
                            <Btn
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                widget.link && history.push(widget.link);
                              }}
                            >
                              {widget.linkText}
                            </Btn>
                          </Bx>
                        </Bx>
                      ))}
                    {contacts.length > 0 && (
                      <Bx mt={linkList ? 2 : undefined}>
                        <ListWrapper divider="none">
                          {contacts.map((contact, idx) => (
                            <ExtendedListItem key={idx} py={1}>
                              <ContactCard contact={contact} />
                            </ExtendedListItem>
                          ))}
                        </ListWrapper>
                      </Bx>
                    )}
                  </Bx>
                  {/* <Bx px={4}>  
                    <Divider direction="vertical" height="100%" />
                  </Bx> */}
                </Bx>
              </Grd>
            )}
          </Grd>
        </Bx>
      </Ctn>
    </Bx>
  );
};
