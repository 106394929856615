import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { HeaderV2 } from '../components/header-v2';
import { SearchSection } from '../components/search-section';
import { SearchResults } from '../components/search-results';
import { MyFacilities } from '../components/my-facilities';
import { DetailView } from '../components/detail-view';
import { PageView } from '../components/page-view';
import { INavigation } from '../model/page';
import { EditView } from '../components/edit-view';
import { FooterLinks } from '../components/footer/links';
import { FooterLogin } from '../components/footer/login';
import { FooterMeta } from '../components/footer/meta';

import { AppContext, IAppState } from '../model/app-context';
import { ReadSpeaker } from '../components/readspeaker';
import { setReadspeakerUsePost } from '../components/readspeaker/lib';
import { Helmet } from 'react-helmet';
import { TrackingComponent } from '../components/tracking';

export const BreakpointContext = React.createContext('');

export const App = () => {
  const [state, setState] = useState<IAppState>({ loggedIn: false });
  const [navigation, setNavigation] = useState(undefined as INavigation | undefined);

  const startup = () => {
    return fetch('/uwao-api/care-erlangen/startup').then(response => {
      if (response.ok && response.status === 200) {
        return response.json().then(json => {
          setState(state => {
            return { ...state, loggedIn: true, username: json.username };
          });
        });
      }
    });
  };
  useEffect(() => {
    startup().then(() => {
      window.document.getElementById('klaro-cookie-notice')?.focus();
    });
  }, []);
  useEffect(() => {
    if (!navigation) {
      const load = async () => {
        const response = await fetch('/uwao-api/care-erlangen/page');
        const json = await response.json();
        setNavigation(json);
      };
      load();
    }
  }, [navigation]);
  return (
    <AppContext.Provider value={{ state, setState, startup }}>
      <TrackingComponent />
      <ReadSpeaker
        customerId={5494}
        onLoadCallback={() => {
          setReadspeakerUsePost();
        }}
      />
      <HeaderV2 navigation={navigation} />
      <main>
        <Switch>
          <Route path="/" exact>
            <Helmet>
              <title>Pflegeplatzbörse</title>
            </Helmet>
            <Redirect to="/startseite" />
          </Route>
          <Route path="/my-facilities" exact>
            <Helmet>
              <title>{'Meine Einrichtungen - Pflegeplatzbörse'}</title>
            </Helmet>
            <MyFacilities />
          </Route>
          <Route path="/edit/:identifier" exact>
            {/*Helmet is inside EditView!!*/}
            <EditView />
          </Route>
          <Route path="/search" exact>
            <Helmet>
              <title>{'Suchergebnisse - Pflegeplatzbörse'}</title>
            </Helmet>
            <SearchResults />
          </Route>
          <Route path="/detail/:identifier" exact>
            {/*Helmet is inside DetailView!!*/}
            <DetailView />
          </Route>
          <Route path="/startseite" exact>
            <Helmet>
              <title>Startseite - Pflegeplatzbörse</title>
            </Helmet>
            <SearchSection />
            <PageView centered navigation={navigation} />
          </Route>
          <Route path={['/startseite/:category', '/startseite/:category/:page', '/startseite/:category/:page/:subpage']} exact>
            <PageView navigation={navigation} />
          </Route>
        </Switch>
      </main>
      <footer className="print-hidden">
        <FooterLogin />
        <FooterLinks navigation={navigation} />
        <FooterMeta />
      </footer>
    </AppContext.Provider>
  );
};
