import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Bx, TabList, TabItem, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import clsx from 'clsx';

const tabItems = [
  {
    id: 'st',
    label: 'Stationäre Versorgung',
    image: '/assets/stationaere-versorgung.svg'
  },
  {
    id: 'se',
    label: 'Teilstationäre Versorgung',
    image: '/assets/teilstationaere-pflege.svg'
  },
  {
    id: 'al',
    label: 'Betreutes Wohnen',
    image: '/assets/betreutes-wohnen.svg'
  }
];

const useStyles = makeStyles(
  theme => ({
    tabListRoot: {
      backgroundColor: 'transparent',
      overflow: 'visible',
      '& .MuiTabs-scrollable': {
        // overflow: 'visible',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent'
      }
    },
    tabItemRoot: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `${theme.shadows[3]}`,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      position: 'relative',
      paddingLeft: 5,
      paddingRight: 5,

      '&:hover:not(.Mui-selected)': {
        '&:before': {
          opacity: 0.25
        }
      },

      '& .MuiTouchRipple-rippleVisible': {
        color: theme?.palette?.quaternary?.main
      },

      '& .MuiTab-wrapper': {
        opacity: 0.75,
        transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shortest, easing: theme.transitions.easing.easeInOut })
      },

      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: 5,
        backgroundColor: theme?.palette?.tertiary?.main,
        borderRadius: theme.shape.borderRadius,
        opacity: 0,
        transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shortest, easing: theme.transitions.easing.easeInOut })
      },

      '&.Mui-selected': {
        boxShadow: '-5px 0px 6px 0 rgba(0,0,0,.15)',
        zIndex: 5,

        '& .MuiTab-wrapper': {
          opacity: 1
        },

        '&:before': {
          opacity: 1
        }
      }
    },
    tabItemRootMobile: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: theme.shape.borderRadius,
      '&.Mui-selected': {
        boxShadow: '0px -5px 6px 0 rgba(0,0,0,.15)'
      }
    }
  }),
  {
    name: 'TabList',
    meta: 'PPB_Custom-TabList'
  }
);

export const ContextSelection: React.FC<{ value?: string; onValueChange: (value: string) => void }> = ({ value, onValueChange }) => {
  const breakpoint = useBreakpoints();
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    onValueChange(newValue);
  };

  return (
    <Bx>
      <TabList
        className={classes.tabListRoot}
        orientation={breakpoint.mdUp ? 'vertical' : 'horizontal'}
        variant="scrollable"
        scrollButtons="off"
        value={value}
        onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
      >
        {tabItems.map((item, idx) => {
          return (
            <TabItem
              value={item.id}
              key={item.label.toLowerCase().trim() + '_' + idx}
              style={{ zIndex: value === item.id ? tabItems.length + 1 : tabItems.length - idx }}
              label={TabItemContent(item.label, item.image)}
              m={0}
              p={0}
              className={clsx(classes.tabItemRoot, !breakpoint.mdUp && classes.tabItemRootMobile)}
            />
          );
        })}
      </TabList>
    </Bx>
  );
};

const TabItemContent = (label: string, image: string) => {
  const breakpoint = useBreakpoints();
  return (
    <Bx>
      {breakpoint.mdUp && (
        <Bx width={165} height={165} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {image && (
            <Bx mb={label ? 3 : undefined}>
              <img src={image} alt={label ? 'Symbolbild für ' + label : 'Symbolbild einer Pflegeleistung'} />
            </Bx>
          )}
          {label && (
            <Typo variant="body2" fontWeight={700}>
              {label}
            </Typo>
          )}
        </Bx>
      )}
      {!breakpoint.mdUp && (
        <Bx display="flex" alignItems="center" justifyContent="center" p={2}>
          {image && (
            <Bx height={28} mr={2}>
              <img src={image} alt={label ? 'Symbolbild für ' + label : 'Symbolbild einer Pflegeleistung'} style={{ height: '100%' }} />
            </Bx>
          )}
          {label && (
            <Typo variant="body2" fontWeight={700}>
              {label}
            </Typo>
          )}
        </Bx>
      )}
    </Bx>
  );
};
