import React, { useContext, useState } from 'react';
import { Bx, Anohana, Btn, Typo, Ctn } from '@curry-group/mui-curcuma';

import { LoginForm } from './form';
import { AppContext } from '../../../model/app-context';
import { useHistory } from 'react-router-dom';

export const FooterLogin: React.FC = () => {
  const history = useHistory();
  const [loginVisible, setLoginVisible] = useState(false);
  const appState = useContext(AppContext);
  return (
    <Bx style={{ width: '100%', backgroundColor: '#ebebeb' }} className="print-hidden">
      <Ctn maxWidth="lg" disableGutters={false}>
        <Bx display="flex" justifyContent="center" alignItems="center" py={2}>
          {!appState.state?.loggedIn && (
            <>
              <Typo style={{ marginRight: '0.75rem' }}>Pflegeeinrichtungen eintragen & verwalten:</Typo>
              <Btn
                variant="outlined"
                size="medium"
                onClick={() => {
                  setLoginVisible(true);
                }}
              >
                Anmelden
              </Btn>
            </>
          )}
          {appState.state?.loggedIn && (
            <>
              <Typo style={{ marginRight: '0.75rem' }}>Angemeldet als "{appState.state.username}"</Typo>
              <Btn
                variant="outlined"
                size="medium"
                onClick={() => {
                  fetch('/uwao-api/core/auth/logout').then(() => {
                    appState.setState?.({ ...appState.state, loggedIn: false });
                    history.push('/');
                  });
                }}
              >
                Abmelden
              </Btn>
            </>
          )}
        </Bx>
      </Ctn>
      <Anohana
        keepMounted
        open={loginVisible}
        onClose={() => {
          setLoginVisible(false);
        }}
      >
        <LoginForm
          closeButton
          onClose={() => {
            setLoginVisible(false);
          }}
          onLogin={() => {
            setLoginVisible(false);
          }}
        />
      </Anohana>
    </Bx>
  );
};
