import { Ctn, Bx, Link, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import React from 'react';

declare var klaro: any;

export const FooterMeta: React.FC = () => {
  const breakpoints = useBreakpoints();
  return (
    <Bx bgcolor="#d6d6d6" py={2}>
      <Ctn maxWidth="lg" disableGutters={false}>
        <Bx display="flex" justifyContent={breakpoints.lgUp ? 'space-between' : undefined} flexDirection={breakpoints.mdDown ? 'column' : undefined} alignItems="center">
          <Bx>
            <Typo variant="body2" align={breakpoints.mdDown ? 'center' : undefined}>
              Die Pflegeplatzbörse: Stadt Erlangen & Landkreis Erlangen-Höchstadt ist ein Angebot der Stadt Erlangen.
            </Typo>
          </Bx>
          <Bx display="flex" flexWrap="wrap">
            <Bx>
              <Link to="/startseite/meta/impressum">Impressum</Link>
            </Bx>
            <Bx ml={2}>
              <Link to="/startseite/meta/datenschutz">Datenschutzerklärung</Link>
            </Bx>
            <Bx ml={2}>
              <Link to="/startseite/meta/barrierefreiheit">Barrierefreiheit</Link>
            </Bx>
            <Bx ml={2}>
              <Link
                to="#"
                id="cookie-consent-reopener"
                onClick={(e: any) => {
                  e.preventDefault();
                  klaro.show();
                  sessionStorage.setItem('triggerElement', e.target.id);
                  document.querySelector('body')?.classList.add('klaro-active');
                  const els = document.querySelectorAll('a:not(.cma-link),button:not(.cm-btn),input:not(.cm-input),textarea,video,source,iframe');
                  for (let i=0; i < els.length; i++) {
                      els[i].setAttribute("tabindex", "-1");
                  }
                }}
              >
                Cookie-Einstellungen
              </Link>
            </Bx>
          </Bx>
        </Bx>
      </Ctn>
    </Bx>
  );
};
