import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CurcumaThemeProvider } from '@curry-group/mui-curcuma';
import { App } from './app';
import { customTheme } from './theme';
import 'dayjs/locale/de';
import dayjs from 'dayjs';
import './theme.scss';
import './print.scss';

dayjs.locale('de');

ReactDOM.render(
  <CurcumaThemeProvider theme={customTheme} breakpointDebounce={150}>
    <CssBaseline />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </CurcumaThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
