import { TextField } from '@material-ui/core';
import React from 'react';

export const EditControlFreeText: React.FC<{ id: string; value: string; onValueChange: (value: string) => void }> = ({ id, value, onValueChange }) => {
  return (
    <TextField
      id={id}
      variant="outlined"
      fullWidth
      multiline
      rowsMax={5}
      rows={3}
      value={value}
      onChange={e => {
        onValueChange?.(e.target.value);
      }}
    />
  );
};
