import React from 'react';

export interface IAppState {
  loggedIn?: boolean;
  username?: string;
}

type TSetAppState = (state: IAppState) => void;

export const AppContext = React.createContext<{ state?: IAppState; setState?: TSetAppState; startup?: () => Promise<void> | undefined }>({});
