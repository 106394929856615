import { Typo, Bx, Grd } from '@curry-group/mui-curcuma';
import { Card, CardActionArea, CardContent, CardMedia, Hidden } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IThing } from '../../../model/thing';
import { IFacility } from '../../../model/facility';
import { freePlacesText } from '../../../parts';
import dayjs from 'dayjs';

export const FacilityListItem: React.FC<{ item: IThing<IFacility>; to: string }> = ({ item, to }) => {
  const history = useHistory();
  const fallbackImgUrl = '/assets/facility-fallback.jfif';
  const imageUrl = item.content?.keyvisual?.fullPath ? '/uwao-api/core/asset/' + item.content.keyvisual.fullPath + '?tn=1&s=gallery_m' : fallbackImgUrl;
  return (
    <Card elevation={2} classes={{ root: 'CustomizedCard' }}>
      <CardActionArea
        TouchRippleProps={{ classes: { root: 'CustomizedTouchRipple' } }}
        href={to}
        onClick={e => {
          e.preventDefault();
          history.push(to);
        }}
        className="list-item"
      >
        <Grd container>
          <Hidden xsDown>
            <Grd item xs={12} sm={4} md={3} style={{ position: 'relative' }}>
              <CardMedia title={'Titelbild der Einrichtung: ' + item.content?.name} image={imageUrl} style={{ height: '100%' }} />
              {item?.content?.placesFree && item.content?.placesFree > 0 && (
                <Bx position="absolute" width="100%" bottom={8} display="flex" justifyContent="center">
                  <Bx
                    color="primary.dark"
                    bgcolor="quaternary.light"
                    borderRadius="borderRadius"
                    py={0.25}
                    px={1.5}
                    fontWeight="bold"
                    fontSize="body2boosted"
                    display="inline-block"
                    className="OverflowBadge"
                    boxShadow={3}
                  >
                    Freie Plätze verfügbar
                  </Bx>
                </Bx>
              )}
            </Grd>
          </Hidden>

          <Grd item xs={12} sm={8} md={9}>
            <CardContent style={{ padding: 0 }}>
              <Bx p={3}>
                <Grd container spacing={3}>
                  <Grd item xs={12}>
                    <Bx display="flex" flexDirection="column">
                      <Typo variant="h5" component="h5" color="primary.main" maxLines={2} className="CardHeadline">
                        {item.content?.name}
                      </Typo>
                      <Bx display="flex" justifyContent="space-between">
                        <Typo variant="body1" color="textSecondary" maxLines={1}>
                          {item.content?.zipCode + ', ' + item.content?.location}
                        </Typo>
                        {!!item.content?.distance && (
                          <Typo variant="body1" color="textSecondary">
                            {((item.content?.distance ?? 0) / 1000).toFixed(2)} km
                          </Typo>
                        )}
                      </Bx>
                    </Bx>
                  </Grd>
                  {item?.content?.placesFree && item.content?.placesFree > 0 && (
                    <Grd item xs={12}>
                      <Bx width="100%" display={{ xs: 'flex', sm: 'none' }} justifyContent="center">
                        <Bx
                          color="primary.dark"
                          bgcolor="quaternary.light"
                          borderRadius="borderRadius"
                          py={0.25}
                          px={1.5}
                          fontWeight="bold"
                          fontSize="body2boosted"
                          display="inline-block"
                          className="OverflowBadge"
                          boxShadow={3}
                        >
                          Freie Plätze verfügbar
                        </Bx>
                      </Bx>
                    </Grd>
                  )}
                  <Grd item xs={12} md={3}>
                    <Typo variant="subtitle2boosted" fontWeight="bold" color="textSecondary" textTransform="uppercase">
                      Plätze
                    </Typo>
                    <Typo>{freePlacesText(item.content)}</Typo>
                  </Grd>
                  <Grd item xs={12} md={3}>
                    <Typo variant="subtitle2boosted" fontWeight="bold" color="textSecondary" textTransform="uppercase">
                      Telefon
                    </Typo>
                    <Typo>{item.content?.telephone}</Typo>
                  </Grd>
                  <Grd item xs={12} md={6}>
                    <Typo variant="subtitle2boosted" fontWeight="bold" color="textSecondary" textTransform="uppercase">
                      E-Mail
                    </Typo>
                    <Typo maxLines={2}>{item.content?.publicMail}</Typo>
                  </Grd>
                  <Grd item xs={12}>
                    <Typo variant="subtitle2boosted" fontWeight="bold" color="textSecondary" textTransform="uppercase">
                      Zuletzt aktualisiert
                    </Typo>
                    <Typo maxLines={2}>{item?.content?.lastUpdate ? dayjs(item.content.lastUpdate * 1000).format('DD.MM.YYYY') : 'Keine Angabe'}</Typo>
                  </Grd>
                </Grd>
              </Bx>
            </CardContent>
          </Grd>
        </Grd>
      </CardActionArea>
    </Card>
  );
};
