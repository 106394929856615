import { Typo, Bx, Ctn } from '@curry-group/mui-curcuma';
import { Card, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';

import { IFacility } from '../../model/facility';
import { IThing } from '../../model/thing';
import { BackButton } from '../buttons/back';
import { SaveButton } from '../buttons/save';
import { FacilityEditItem } from '../facility-item/edit';

export const EditView: React.FC = () => {
  const params = useParams<any>();
  const history = useHistory();
  const [facility, setFacility] = useState(undefined as IThing<IFacility> | undefined);
  useEffect(() => {
    window.scrollTo(0, 0);

    const load = async () => {
      const response = await fetch('/uwao-api/care-erlangen/facilities/facility/' + params.identifier + '?edit=1');
      const json = await response.json();
      setFacility(json);
    };
    load();
  }, [params.identifier]);
  return (
    <Bx pt={10} pb={10} display="flex" flexDirection="column">
      <Helmet>
        <title>{(facility?.content?.name ? facility?.content?.name + ' bearbeiten - ' : '') + 'Pflegeplatzbörse'}</title>
      </Helmet>
      <Ctn maxWidth="lg" disableGutters={false}>
        <Bx width="100%" pb={4}>
          <Typo component="h3" variant="h1" color="primary">
            {facility?.content?.name}
          </Typo>
        </Bx>
      </Ctn>
      <Ctn maxWidth="lg" disableGutters={false}>
        <Card elevation={2}>
          <Bx p={2}>
            <BackButton to={'/my-facilities'} />
          </Bx>
          <Divider />
          <FacilityEditItem item={facility} setItem={setFacility} />
          <Divider />
          <Bx p={2}>
            <SaveButton
              onClick={async () => {
                const response = await fetch('/uwao-api/care-erlangen/facilities/facility/update', {
                  method: 'POST',
                  headers: {
                    'content-type': 'application/json'
                  },
                  body: JSON.stringify(facility)
                });
                if (response.status === 200) {
                  history.push('/my-facilities');
                }
              }}
            />
          </Bx>
        </Card>
      </Ctn>
    </Bx>
  );
};
