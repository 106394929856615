import { Link, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { IFacility } from '../../../../../model/facility';
import { IThing } from '../../../../../model/thing';
import { PBList } from '../../../helper/list';
import { PBListItem } from '../../../helper/list/item';

export const DownloadsTab: React.FunctionComponent<{ item?: IThing<IFacility> }> = ({ item }) => {
  return (
    <>
      {item?.content?.downloads && item.content.downloads.length > 0 && (
        <PBList>
          {item.content.downloads.map(dl => {
            return (
              <PBListItem key={dl._id}>
                <Link to="" component="a" color="primary" href={'/uwao-api/core/asset/' + dl.fullPath} title={"zum Download der " + dl.extension + "-Datei "}>
                  <Typo>{dl.displayName}{dl.extension}</Typo>
                </Link>
              </PBListItem>
            );
          })}
        </PBList>
      )}
    </>
  );
};
