import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Grd,
  Bx,
  Anohana,
  Btn,
  CheckboxControl,
  IconBtn,
  Input,
  Typo,
} from "@curry-group/mui-curcuma";
import { FormControl, Slider, TextField, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faMapMarkerSlash,
  faUndo,
} from "@fortawesome/pro-light-svg-icons";

import { AssistedLivingFilter } from "./assisted-living";
import { SemiStationaryFilter } from "./semi-stationary";
import { StationaryFilter } from "./stationary";
import { useHistory, useLocation } from "react-router-dom";
import { SearchButton } from "../buttons/search";
import { LocatorForm } from "./locator-form";
import { defaultCoords } from "../../parts";

const sortOptions = [
  { id: 1, t: "Entfernung aufsteigend" },
  { id: 2, t: "Entfernung absteigend" },
  { id: 3, t: "Alphabetisch aufsteigend" },
  { id: 4, t: "Alphabetisch absteigend" },
];

const freePlacesLabel = {
  st: "Freie Plätze",
  se: "Freie Plätze",
  al: "Freie Appartements",
};

const headlines = {
  st: "Suche nach Plätzen in der Stationären Versorgung",
  se: "Suche nach Plätzen in der Teilstationären Versorgung",
  al: "Suche nach Appartements im betreuten Wohnen",
};

const careType = {
  st: "Stationäre Versorgung",
  se: "Teilstationäre Versorgung",
  al: "BetreutesWohnen",
};

const createParams = (
  locSearch: string,
  location: string,
  facilityName: string,
  radius: number,
  sort: number,
  freeOnly: number,
  apartmentSizes: number[],
  careTypes: number[],
  carePlaceTypes: number[],
  specialCareOffers: number[],
  latLon: [number, number]
) => {
  const params = new URLSearchParams(locSearch);
  if (location) {
    params.set("l", location);
  } else {
    params.delete("l");
  }
  if (facilityName) {
    params.set("fn", facilityName);
  } else {
    params.delete("fn");
  }
  if ((radius ?? 100) === 100) {
    params.delete("r");
  } else {
    params.set("r", (radius ?? 100).toString());
  }
  params.set("s", sort.toString());
  params.set("fo", freeOnly ? "1" : "0");
  if (apartmentSizes && apartmentSizes.length > 0) {
    params.set("as", apartmentSizes.join("-"));
  } else {
    params.delete("as");
  }
  if (careTypes && careTypes.length > 0) {
    params.set("ct", careTypes.join("-"));
  } else {
    params.delete("ct");
  }
  if (carePlaceTypes && carePlaceTypes.length > 0) {
    params.set("cpt", carePlaceTypes.join("-"));
  } else {
    params.delete("cpt");
  }
  if (specialCareOffers && specialCareOffers.length > 0) {
    params.set("sco", specialCareOffers.join("-"));
  } else {
    params.delete("sco");
  }
  if (
    latLon &&
    latLon[0] !== defaultCoords[0] &&
    latLon[1] !== defaultCoords[1]
  ) {
    params.set("ll", latLon[0] + "-" + latLon[1]);
  } else {
    params.delete("ll");
  }
  if (!params.get("fc")) {
    params.set("fc", "st");
  }
  return params.toString();
};

export const SearchFilter: React.FC<{
  hideReset?: boolean;
  hideExtended?: boolean;
  hideSort?: boolean;
  hideSearch?: boolean;
  asSidebar?: boolean;
}> = ({ hideReset, hideExtended, hideSort, hideSearch, asSidebar }) => {
  const theme = useTheme();
  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);
  const timeout = useRef<any | undefined>(undefined);

  const prevReset = useRef(0);
  const queryCoordsString = searchParams.get("ll");
  let queryCoords = defaultCoords;
  if (queryCoordsString && queryCoordsString.trim()) {
    const splitted = queryCoordsString.split("-");
    if (splitted.length === 2) {
      queryCoords = [0, 0];
      queryCoords[0] = parseFloat(splitted[0]);
      queryCoords[1] = parseFloat(splitted[1]);
    }
  }
  const [latLon, setLatLon] = useState<[number, number]>(queryCoords);
  const [locatorVisible, setLocatorVisible] = useState(false);
  const [reset, setReset] = useState(0);
  const [location, setLocation] = useState(searchParams.get("l") ?? "");
  const [facilityName, setFacilityName] = useState(
    searchParams.get("fn") ?? ""
  );
  const [radius, setRadius] = useState(
    parseInt(searchParams.get("r") ?? "100")
  );
  const [sort, setSort] = useState(parseInt(searchParams.get("s") ?? "3"));
  const [freeOnly, setFreeOnly] = useState(
    parseInt(searchParams.get("fo") ?? "0")
  );
  const apartmentSizesString = searchParams.get("as") ?? "";
  const apartmentSizesArray = apartmentSizesString
    .split("-")
    .map((ass) => parseInt(ass))
    .filter((ass) => !isNaN(ass));
  const [apartmentSizes, setApartmentSizes] = useState(apartmentSizesArray);
  const careTypesString = searchParams.get("ct") ?? "";
  const careTypesArray = careTypesString
    .split("-")
    .map((ct) => parseInt(ct))
    .filter((ct) => !isNaN(ct));
  const [careTypes, setCareTypes] = useState(careTypesArray);

  const carePlaceTypesString = searchParams.get("cpt") ?? "";
  const carePlaceTypesArray = carePlaceTypesString
    .split("-")
    .map((ct) => parseInt(ct))
    .filter((ct) => !isNaN(ct));
  const [carePlaceTypes, setCarePlaceTypes] = useState(carePlaceTypesArray);

  const specialCareOffersString = searchParams.get("sco") ?? "";
  const specialCareOffersArray = specialCareOffersString
    .split("-")
    .map((ct) => parseInt(ct))
    .filter((ct) => !isNaN(ct));
  const [specialCareOffers, setSpecialCareOffers] = useState(
    specialCareOffersArray
  );
  const facilityCareType = searchParams.get("fc");
  const history = useHistory();
  const resetLocation = useCallback(() => {
    setFreeOnly(0);
    setLocation("");
    setRadius(100);
    setSort(3);
    setFacilityName("");
    setApartmentSizes([]);
    setCareTypes([]);
    setCarePlaceTypes([]);
    setSpecialCareOffers([]);
    setReset(Date.now());
  }, []);
  useEffect(() => {
    if (facilityCareType === "al") {
      setCareTypes([]);
      setCarePlaceTypes([]);
      setSpecialCareOffers([]);
    }
    if (facilityCareType === "se") {
      setApartmentSizes([]);
      setCarePlaceTypes([]);
      setSpecialCareOffers([]);
    }
    if (facilityCareType === "st") {
      setApartmentSizes([]);
      setCareTypes([]);
    }
  }, [facilityCareType]);

  useEffect(() => {
    const updateLocation = () => {
      const pReset = prevReset.current;
      prevReset.current = reset;
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(
        () => {
          if (
            history.location.search !==
            "?" +
              createParams(
                loc.search,
                location,
                facilityName,
                radius,
                sort,
                freeOnly,
                apartmentSizes,
                careTypes,
                carePlaceTypes,
                specialCareOffers,
                latLon
              )
          ) {
            history.push(
              loc.pathname +
                "?" +
                createParams(
                  loc.search,
                  location,
                  facilityName,
                  radius,
                  sort,
                  freeOnly,
                  apartmentSizes,
                  careTypes,
                  carePlaceTypes,
                  specialCareOffers,
                  latLon
                )
            );
          }
        },
        pReset !== reset ? 0 : 500
      );
    };
    updateLocation();
  }, [
    loc.search,
    loc.pathname,
    history,
    location,
    radius,
    sort,
    freeOnly,
    apartmentSizes,
    careTypes,
    facilityName,
    carePlaceTypes,
    specialCareOffers,
    reset,
    latLon,
  ]);
  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);
  return (
    <form
      role="dialog"
      aria-label={
        headlines[facilityCareType as "st" | "se" | "al"] +
        " für " +
        careType[facilityCareType as "st" | "se" | "al"]
      }
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {!asSidebar && facilityCareType && (
        <Bx mb={2}>
          <Typo variant="h4">
            {headlines[facilityCareType as "st" | "se" | "al"]}
          </Typo>
        </Bx>
      )}
      <Grd container spacing={3}>
        {!hideReset && (
          <Grd item xs={12}>
            <Bx display="flex" justifyContent="flex-end">
              <Bx
                display="flex"
                alignItems="center"
                width="100%"
                style={{ borderBottom: `1px solid ${theme.palette.divider}` }}
                pb={1.5}
              >
                <Typo variant="h4" component="h4">
                  Filtern
                </Typo>
                <Bx ml="auto">
                  <Tooltip arrow title="Setzen Sie die Suche zurück">
                    <IconBtn
                      onClick={resetLocation}
                      variant="text"
                      size="small"
                    >
                      <FontAwesomeIcon icon={faUndo} />
                    </IconBtn>
                  </Tooltip>
                </Bx>
              </Bx>
              {/* {!asSidebar && (
              <>
              <Bx p={1} />
              <Tooltip arrow title="Adresse finden oder Standort ermitteln">
              <IconBtn
              color="primary"
              variant="contained"
              onClick={() => {
                setLocatorVisible(true);
              }}
              >
              <FontAwesomeIcon icon={faLocationArrow} />
              </IconBtn>
              </Tooltip>
              </>
            )} */}
            </Bx>
          </Grd>
        )}
        {!hideSort && (
          <Grd item xs={12}>
            {asSidebar ? (
              <Input
                inputLabel={{ children: "Sortierung", htmlFor: "sort-facette" }}
                inputComponent={
                  <Autocomplete
                    id="sort-facette"
                    getOptionLabel={(option) =>
                      sortOptions.find((so) => so.id === option)?.t ?? ""
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    options={sortOptions.map((so) => so.id)}
                    value={sort}
                    onChange={(e, v) => {
                      setSort(v ?? 3);
                    }}
                  />
                }
              />
            ) : (
              <FormControl fullWidth>
                <Autocomplete
                  id="care-type"
                  getOptionLabel={(option) =>
                    sortOptions.find((so) => so.id === option)?.t ?? ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sortierung"
                      variant={asSidebar ? "outlined" : "standard"}
                    />
                  )}
                  options={sortOptions.map((so) => so.id)}
                  value={sort}
                  onChange={(e, v) => {
                    setSort(v ?? 3);
                  }}
                />
              </FormControl>
            )}
          </Grd>
        )}
        <Grd item xs={12}>
          {asSidebar ? (
            <Input
              inputLabel={{ children: "Ort, Straße, PLZ" }}
              input={{
                placeholder: "Ort, Straße, PLZ eingeben",
                value: location,
                onChange: (e) => setLocation(e.target.value ?? ""),
              }}
            />
          ) : (
            <TextField
              id="location"
              label="Ort, Straße, PLZ"
              value={location}
              onChange={(e) => {
                setLocation(e.target.value ?? "");
              }}
              fullWidth
            />
          )}
        </Grd>
        <Grd item xs={12}>
          {asSidebar ? (
            <Input
              inputLabel={{ children: "Name der Einrichtung" }}
              input={{
                placeholder: "Name eingeben",
                value: facilityName,
                onChange: (e) => setFacilityName(e.target.value ?? ""),
              }}
            />
          ) : (
            <TextField
              id="facility-name"
              label="Name der Einrichtung"
              value={facilityName}
              onChange={(e) => {
                setFacilityName(e.target.value ?? "");
              }}
              fullWidth
            />
          )}
        </Grd>
        <Grd item xs={12}>
          <Bx mt={asSidebar ? undefined : 1}>
            <CheckboxControl
              label={
                <Bx component="span" fontWeight="bold">
                  {freePlacesLabel[facilityCareType as "st" | "se" | "al"]}
                </Bx>
              }
              control={{ color: "primary" }}
              checked={!!freeOnly}
              onChange={(e, v) => {
                setFreeOnly?.(v ? 1 : 0);
              }}
            />
          </Bx>
        </Grd>

        <Grd item xs={12}>
          <Typo
            gutterBottom
            style={{ color: asSidebar ? "#202020" : "#6e6e6e" }}
            variant={asSidebar ? "body2boosted" : undefined}
          >
            Im Umkreis suchen
          </Typo>
          <Bx mt={1}>
            {(!latLon || latLon === defaultCoords) && (
              <Tooltip
                arrow
                title="Um im Umkreis zu suchen müssen Sie eine Adresse hinterlegen"
              >
                <Btn
                  fullWidth={asSidebar}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setLocatorVisible(true);
                  }}
                  startIcon={<FontAwesomeIcon icon={faMapMarker} />}
                >
                  Adresse hinterlegen
                </Btn>
              </Tooltip>
            )}
            {latLon && latLon !== defaultCoords && (
              <Btn
                fullWidth={asSidebar}
                color="default"
                variant="contained"
                onClick={() => {
                  setRadius(100);
                  setLocation("");
                  setLatLon(defaultCoords);
                }}
                startIcon={<FontAwesomeIcon icon={faMapMarkerSlash} />}
              >
                Adresse entfernen
              </Btn>
            )}
          </Bx>
        </Grd>

        {latLon && latLon !== defaultCoords && (
          <Grd item xs={12}>
            <Bx mt={asSidebar ? 0.5 : 2}>
              <Typo
                gutterBottom
                style={{ color: asSidebar ? "#202020" : "#6e6e6e" }}
                variant={asSidebar ? "body2boosted" : undefined}
              >
                Radius
              </Typo>
              <Slider
                step={5}
                max={100}
                min={5}
                defaultValue={5}
                value={radius}
                onChange={(e, v) => {
                  if (!Array.isArray(v)) {
                    setRadius(v);
                  }
                }}
                valueLabelDisplay="auto"
                marks={[
                  { value: 5, label: "5km" },
                  { value: 20, label: "20km" },
                  { value: 40, label: "40km" },
                  { value: 65, label: "65km" },
                  { value: 100, label: "100km" },
                ]}
              />
            </Bx>
          </Grd>
        )}
        {asSidebar && (
          <Grd item xs={12}>
            <Bx mt={1}></Bx>
          </Grd>
        )}

        <Grd item xs={12}>
          {!asSidebar && facilityCareType === "st" && (
            <Typo
              gutterBottom
              style={{ color: asSidebar ? "#202020" : "#6e6e6e" }}
              variant={asSidebar ? "body2boosted" : undefined}
            >
              Art der Pflege
            </Typo>
          )}
          {!asSidebar && facilityCareType === "se" && (
            <Typo
              gutterBottom
              style={{ color: asSidebar ? "#202020" : "#6e6e6e" }}
              variant={asSidebar ? "body2boosted" : undefined}
            >
              Art der Betreuung
            </Typo>
          )}
          {facilityCareType === "st" && (
            <StationaryFilter
              collapsible={asSidebar}
              hideExtended={hideExtended}
              carePlaceTypes={carePlaceTypes}
              setCarePlaceTypes={setCarePlaceTypes}
              specialCareOffers={specialCareOffers}
              setSpecialCareOffers={setSpecialCareOffers}
            />
          )}
          {facilityCareType === "se" && (
            <SemiStationaryFilter
              collapsible={asSidebar}
              careTypes={careTypes}
              setCareTypes={setCareTypes}
            />
          )}
          {facilityCareType === "al" && (
            <AssistedLivingFilter
              collapsible={asSidebar}
              hideExtended={hideExtended}
              apartmentSizes={apartmentSizes}
              setApartmentSizes={setApartmentSizes}
            />
          )}
        </Grd>

        {!hideSearch && (
          <Grd item xs={12}>
            <Bx mt={2}>
              <SearchButton
                onClick={() => {
                  if (timeout.current) {
                    clearTimeout(timeout.current);
                  }

                  history.push(
                    "/search?" +
                      createParams(
                        loc.search,
                        location,
                        facilityName,
                        radius,
                        sort,
                        freeOnly,
                        apartmentSizes,
                        careTypes,
                        carePlaceTypes,
                        specialCareOffers,
                        latLon
                      )
                  );
                }}
              />
            </Bx>
          </Grd>
        )}
      </Grd>
      <Anohana
        keepMounted
        open={locatorVisible}
        onClose={() => {
          setLocatorVisible(false);
        }}
      >
        <LocatorForm
          initialLatLon={latLon}
          onAccept={setLatLon}
          onClose={() => {
            setLocatorVisible(false);
          }}
        />
      </Anohana>
    </form>
  );
};
