import { Ctn, Grd, Bx, Typo } from '@curry-group/mui-curcuma';
import React, { useEffect, useState, useContext } from 'react';
import { IFacility } from '../../model/facility';
import { IThing } from '../../model/thing';
import { FacilityListItem } from '../facility-item/list';
import { AppContext } from '../../model/app-context';

export const MyFacilities: React.FC = () => {
  const [facilities, setFacilities] = useState<IThing<IFacility>[]>([]);
  const appState = useContext(AppContext);
  useEffect(() => {
    const load = async () => {
      const response = await fetch('/uwao-api/care-erlangen/facilities/own');
      const json = await response.json();
      setFacilities(json);
    };
    load();
  }, []);
  return (
    <Bx pt={10} pb={10} height="100%" display="flex" flexDirection="column">
      {appState.state?.loggedIn && (
        <>
          <Ctn maxWidth="lg" disableGutters={false}>
            <Bx width="100%" pb={4}>
              <Typo component="h3" variant="h1" color="primary">
                Meine Einrichtungen
              </Typo>
            </Bx>
          </Ctn>
          <Ctn maxWidth="lg" disableGutters={false}>
            <Grd container spacing={3}>
              {facilities.map(fi => {
                return (
                  <Grd item xs={12} key={fi._id}>
                    <FacilityListItem item={fi} to={'/edit/' + fi.seo?.alias} />
                  </Grd>
                );
              })}
            </Grd>
          </Ctn>
        </>
      )}
      {!appState.state?.loggedIn && (
        <>
          <Ctn maxWidth="lg" disableGutters={false}>
            <Bx width="100%" pb={4}>
              <Typo component="h3" variant="h1" color="primary">
                Anmeldung erforderlich
              </Typo>
            </Bx>
          </Ctn>
          <Ctn maxWidth="lg" disableGutters={false}>
            <Bx width="100%" pb={4}>
              <Typo component="p" color="text.primary">
                Für die Nutzung dieser Funktion müssen Sie sich zunächst auf der Seite anmelden.
              </Typo>
            </Bx>
          </Ctn>
        </>
      )}
    </Bx>
  );
};
