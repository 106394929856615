import { Bx, Typo } from '@curry-group/mui-curcuma';
import dayjs from 'dayjs';
import React from 'react';
import { IFacility } from '../../../../../model/facility';
import { IThing } from '../../../../../model/thing';

export const BusinessHoursTab: React.FunctionComponent<{ item?: IThing<IFacility> }> = ({ item }) => {
  const daysDictionary: {
    [key: string]: string[];
  } = {};
  const managementHours = item?.content?.managementHours || [];
  for (let mhItemIdx in managementHours) {
    const mhItem = managementHours[mhItemIdx];
    const openAt = dayjs.unix(mhItem.openAt ?? Date.now());
    const closeAt = dayjs.unix(mhItem.closeAt ?? Date.now());
    let openCloseString = openAt.format('HH:mm') + ' - ' + closeAt.format('HH:mm');
    const days = mhItem.days || [];
    for (let day of days) {
      if (!daysDictionary[day]) {
        daysDictionary[day] = [];
      }
      daysDictionary[day].push(openCloseString);
    }
  }
  const today = dayjs().format('dddd');

  return (
    <Bx>
      {/* Öffnungszeiten der Verwaltung */}
      <Bx>
        {/* <Bx mb={0.5}>
          <Typo textTransform="uppercase" color="text.secondary" fontWeight="bold" variant="body2boosted" component="strong">
            Adresse
          </Typo>
        </Bx> */}
        <Bx display="flex" justifyContent="space-between" alignItems="flex-start" flexDirection={{ xs: 'column', md: 'row' }}>
          <Bx display="flex" flexDirection="column">
            {Object.keys(daysDictionary).map((dd, idx) => {
              const day = daysDictionary[dd];
              const dayString = item?.content?.daysResolved?.[dd].content?.name;
              const highlight = dayString === today;
              return (
                <Bx display="flex" key={idx} mt={idx !== 0 ? 0.5 : undefined}>
                  <Bx width={120}>
                    <Typo color={highlight ? 'primary' : undefined} style={{ fontWeight: highlight ? 700 : undefined }}>
                      {dayString}
                    </Typo>
                  </Bx>
                  <Bx>
                    {day.map((d, idx) => (
                      <Typo key={idx}>{d}</Typo>
                    ))}
                  </Bx>
                </Bx>
              );
            })}
          </Bx>
        </Bx>
      </Bx>
    </Bx>
  );
};
