import { Btn, Bx, Link, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import { faMapMarked } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IFacility } from '../../../../../model/facility';
import { IThing } from '../../../../../model/thing';

export const ContactTab: React.FunctionComponent<{ item?: IThing<IFacility>; showMap: boolean; setShowMap: Function }> = ({ item, showMap, setShowMap }) => {
  const breakpoints = useBreakpoints();

  return (
    <Bx>
      {/* Adresse */}
      {(item?.content?.location || item?.content?.zipCode || item?.content?.street) && (
        <Bx mb={{ xs: 2, md: 5 }}>
          <Bx mb={0.5}>
            <Typo textTransform="uppercase" color="text.secondary" fontWeight="bold" variant="body2boosted" component="strong">
              Adresse
            </Typo>
          </Bx>
          <Bx display="flex" justifyContent="space-between" alignItems="flex-start" flexDirection={{ xs: 'column', md: 'row' }}>
            <Bx mb={{ xs: 1, md: 0 }} pr={{ xs: 0, md: 2 }}>
              <Typo>{item?.content?.street}</Typo>
              {(item?.content?.zipCode || item?.content?.location) && (
                <Typo>
                  {item?.content?.zipCode ? item?.content?.zipCode + ',' : ''} {item?.content?.location}
                </Typo>
              )}
            </Bx>
            {item?.content?.lon && item?.content?.lat && (
              <Btn variant="contained" startIcon={<FontAwesomeIcon icon={faMapMarked} />} onClick={() => setShowMap(!showMap)} className="print-hidden">
                {showMap ? 'Karte schließen' : 'Auf Karte zeigen'}
              </Btn>
            )}
          </Bx>
        </Bx>
      )}

      {/* Träger & Leitung */}
      <Bx display={{ md: 'flex' }} mb={{ xs: 2, md: 5 }}>
        {/* Träger */}
        {item?.content?.provider && (
          <Bx width={breakpoints.smDown ? undefined : '50%'} pr={{ md: 1 }} mb={{ xs: 2, md: 0 }}>
            <Bx mb={0.5}>
              <Typo textTransform="uppercase" color="text.secondary" fontWeight="bold" variant="body2boosted" component="strong">
                Träger
              </Typo>
            </Bx>
            <Bx display="flex" justifyContent="space-between" alignItems="flex-start">
              <Typo>{item?.content?.provider}</Typo>
            </Bx>
          </Bx>
        )}

        {/* Leitung */}
        {item?.content?.facilityAdministration && (
          <Bx width={breakpoints.smDown ? undefined : '50%'} pl={{ md: 1 }}>
            <Bx mb={0.5}>
              <Typo textTransform="uppercase" color="text.secondary" fontWeight="bold" variant="body2boosted" component="strong">
                Leitung
              </Typo>
            </Bx>
            <Bx display="flex" justifyContent="space-between" alignItems="flex-start">
              <Typo>{item?.content?.facilityAdministration}</Typo>
            </Bx>
          </Bx>
        )}
      </Bx>

      {/* Telefon & Fax */}
      <Bx display={{ md: 'flex' }} mb={{ xs: 2, md: 5 }}>
        {/* Telefon */}
        {item?.content?.telephone && (
          <Bx width={breakpoints.smDown ? undefined : '50%'} pr={{ md: 1 }} mb={{ xs: 2, md: 0 }}>
            <Bx mb={0.5}>
              <Typo textTransform="uppercase" color="text.secondary" fontWeight="bold" variant="body2boosted" component="strong">
                Telefon
              </Typo>
            </Bx>
            <Bx display="flex" justifyContent="space-between" alignItems="flex-start">
              <Link variant="body1" color="primary" component="a" to="" href={'tel:' + item?.content?.telephone}>
                {item?.content?.telephone}
              </Link>
            </Bx>
          </Bx>
        )}

        {/* Fax */}
        {item?.content?.telefax && (
          <Bx width={breakpoints.smDown ? undefined : '50%'} pl={{ md: 1 }}>
            <Bx mb={0.5}>
              <Typo textTransform="uppercase" color="text.secondary" fontWeight="bold" variant="body2boosted" component="strong">
                Fax
              </Typo>
            </Bx>
            <Bx display="flex" justifyContent="space-between" alignItems="flex-start">
              <Link variant="body1" color="primary" component="a" to="" href={'tel:' + item?.content?.telefax}>
                {item?.content?.telefax}
              </Link>
            </Bx>
          </Bx>
        )}
      </Bx>

      {/* E-Mail & Webseite */}
      <Bx display={{ md: 'flex' }}>
        {/* E-Mail */}
        {item?.content?.publicMail && (
          <Bx width={breakpoints.smDown ? undefined : '50%'} pr={{ md: 1 }} mb={{ xs: 2, md: 0 }}>
            <Bx mb={0.5}>
              <Typo textTransform="uppercase" color="text.secondary" fontWeight="bold" variant="body2boosted" component="strong">
                E-Mail
              </Typo>
            </Bx>
            <Bx display="flex" justifyContent="space-between" alignItems="flex-start">
              <Link variant="body1" color="primary" component="a" to="" href={'mailto:' + item?.content?.administratorMail} style={{ wordBreak: 'break-word' }}>
                {item?.content?.publicMail}
              </Link>
            </Bx>
          </Bx>
        )}

        {/* Webseite */}
        {item?.content?.website && (
          <Bx width={breakpoints.smDown ? undefined : '50%'} pl={{ md: 1 }}>
            <Bx mb={0.5}>
              <Typo textTransform="uppercase" color="text.secondary" fontWeight="bold" variant="body2boosted" component="strong">
                Webseite
              </Typo>
            </Bx>
            <Bx display="flex" justifyContent="space-between" alignItems="flex-start">
              <Link
                variant="body1"
                color="primary"
                component="a"
                to=""
                href={'https://' + item?.content?.website.replace('http://', '').replace('https://', '')}
                target="_blank"
                rel="noopener noreferrer"
                style={{ wordBreak: 'break-word' }}
              >
                {item?.content?.website}
              </Link>
            </Bx>
          </Bx>
        )}
      </Bx>
    </Bx>
  );
};
