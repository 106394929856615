import { Typo, Bx, Ctn } from '@curry-group/mui-curcuma';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { FacilityDetailItem } from '../facility-item/detail';
import { BackButton } from '../buttons/back';
import { IFacility } from '../../model/facility';
import { IThing } from '../../model/thing';
import { Helmet } from 'react-helmet';
import { ReadSpeakerButton } from '../readspeaker';
import { PrintButton } from '../print-button';

export const DetailView: React.FC<{}> = () => {
  const loc = useLocation();
  const params = useParams<any>();
  const [facility, setFacility] = useState(undefined as IThing<IFacility> | undefined);
  useEffect(() => {
    window.scrollTo(0, 0);

    const load = async () => {
      const response = await fetch('/uwao-api/care-erlangen/facilities/facility/' + params.identifier + loc.search);
      const json = await response.json();
      setFacility(json);
    };
    load();
  }, [params.identifier, loc.search]);
  const rsClass = 'readspeaker-readable-' + facility?._id;
  return (
    <Bx pt={10} pb={10} display="flex" flexDirection="column" className={rsClass}>
      <Helmet>
        <title>{(facility?.content?.name ? facility?.content?.name + ' - ' : '') + 'Pflegeplatzbörse'}</title>
      </Helmet>
      <Ctn maxWidth="lg" disableGutters={false}>
        <Bx display="flex" width="100%" justifyContent={{ xs: 'flex-start', md: 'space-between' }} flexDirection={{xs: 'column', sm: 'row'}}>
          <Bx mb={{xs: 1, sm: 3}}>
            <Typo component="h3" className="print-as-h2 detail__headline" variant="h1" color="primary">
              {facility?.content?.name}
            </Typo>

            <Bx mt={1}>
              <ReadSpeakerButton readClass={rsClass} style={{ padding: 0 }} />
            </Bx>
          </Bx>
          <Bx pl={{ md: 3 }} mb={3} className="print-hidden" display="flex" alignItems="flex-start">
            <Bx mr={1}>
              <PrintButton />
            </Bx>
            <BackButton to={'/search' + loc.search} />
          </Bx>
        </Bx>
      </Ctn>
      <Ctn maxWidth="lg" disableGutters={false}>
        <FacilityDetailItem item={facility} />
      </Ctn>
    </Bx>
  );
};
