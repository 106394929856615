import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from '../../parts';

export const TrackingComponent: React.FC = () => {
  const loc = useLocation();
  useEffect(() => {
    trackPageView(loc.pathname, loc.pathname);
  }, [loc.pathname]);
  return null;
};
