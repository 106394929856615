import { Bx, Typo, UnsortedList } from "@curry-group/mui-curcuma";
import React from "react";
import { CheckMarkIcon } from "..";
import { IFacility } from "../../../../../model/facility";
import { IThing } from "../../../../../model/thing";

export const CareInfoAssistedLiving: React.FC<{ item?: IThing<IFacility> }> = ({
  item,
}) => {
  const apartments = (item?.content?.apartmentsSubD || []).filter(
    (as) => as.apartmentAvailable
  );

  let freeApparments: any[] = [];
  apartments.forEach((fas, idx) => {
    if (fas.apartmentAvailable) {
      if (
        item?.content?.apartmentSizesResolved &&
        fas.apartmentSize &&
        item?.content?.apartmentSizesResolved[fas.apartmentSize] &&
        item?.content?.apartmentSizesResolved[fas.apartmentSize].content?.name
      ) {
        freeApparments.push({
          children: (
            <>
              {
                item?.content?.apartmentSizesResolved[fas.apartmentSize].content
                  ?.name
              }
            </>
          ),
          icon: <CheckMarkIcon />,
        });
      }
    }
  });

  return (
    <>
      <Bx mb={2}>
        <Typo variant="h3" component="h5" color="textSecondary">
          <Typo color="primary" component="span" variant="inherit">
            {item?.content?.placesFree || 0}
          </Typo>{" "}
          von{" "}
          <Typo color="primary" component="span" variant="inherit">
            {item?.content?.placesTotal || 0}
          </Typo>{" "}
          Appartements{" "}
          <Typo
            color="primary"
            component="span"
            variant="inherit"
            style={{ textDecoration: "underline" }}
          >
            frei
          </Typo>
        </Typo>
      </Bx>
      {freeApparments.length > 0 && (
        <>
          <Bx mb={2}>
            <Typo color="primary" component="strong" fontWeight="bold">
              Verfügbare Appartements:
            </Typo>
          </Bx>
          <Typo component="div">
            <UnsortedList
              spacing={2}
              iconSpacing={1.5}
              items={freeApparments}
            />
          </Typo>
        </>
      )}
      {item?.content?.description && (
        <>
          <Bx mt={4} mb={2}>
            <Typo color="primary" component="strong" fontWeight="bold">
              Hinweise:
            </Typo>
          </Bx>
          <Bx>
            <Typo
              dangerouslySetInnerHTML={{
                __html: (item?.content?.description ?? "")
                  .replaceAll("<p>", "")
                  .replaceAll("</p>", ""),
              }}
            />
          </Bx>
        </>
      )}
    </>
  );
};
