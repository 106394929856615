import { Grd, Bx, CheckboxControl, Typo } from "@curry-group/mui-curcuma";
import { Divider } from "@material-ui/core";
import React from "react";
import { IFacility } from "../../../../model/facility";
import { IThing } from "../../../../model/thing";
import { EditControlFreeText } from "../controls/free-text";
import { EditControlNumber } from "../controls/number";

export const EditStationary: React.FC<{
  item?: IThing<IFacility>;
  setItem?: (item: IThing<IFacility>) => void;
}> = ({ item, setItem }) => {
  // const [fp, setFp] = useState<{ [key: string]: number }>({});
  const carePlaceTypes = item?.content?.carePlaceTypesSubD || [];
  const carePlaceTypesResolved = item?.content?.carePlaceTypesResolved ?? {};
  const roomTypes = item?.content?.roomTypesSubD || [];
  const roomTypesResolved = item?.content?.roomTypesResolved || {};
  const specialCareOffers = item?.content?.specialCareOffersSubD || [];
  const specialCareOffersResolved =
    item?.content?.specialCareOffersResolved || {};
  return (
    <Grd container spacing={3}>
      <Grd item xs={12}>
        <Bx mb={2} display="flex" justifyContent="space-between">
          <Typo variant="h5" color="primary">
            Pflegeplatzart & Anzahl
          </Typo>
        </Bx>
        <Bx>
          {carePlaceTypes.map((cpt, idx) => {
            return (
              <Bx
                key={idx}
                display="flex"
                alignItems="center"
                mt={idx !== 0 ? 1 : undefined}
              >
                <EditControlNumber
                  id={"field-" + idx}
                  value={cpt.carePlaceTypeFree}
                  onValueChange={(value) => {
                    if (carePlaceTypes[idx]) {
                      const changed = { ...carePlaceTypes[idx] };
                      changed.carePlaceTypeFree =
                        value === "" ? undefined : value;
                      const newArray = [...carePlaceTypes];
                      newArray[idx] = changed;
                      setItem?.({
                        ...item,
                        content: {
                          ...item?.content,
                          carePlaceTypesSubD: newArray,
                        },
                      });
                    }
                  }}
                />
                <Bx ml={1}>
                  <Typo component="span">
                    <label htmlFor={"field-" + idx}>
                      {
                        carePlaceTypesResolved[cpt.carePlaceType ?? ""].content
                          ?.name
                      }
                    </label>
                  </Typo>
                </Bx>
              </Bx>
            );
          })}
          <Divider orientation="vertical" />
        </Bx>
      </Grd>
      <Grd item xs={12} sm={6}>
        <Bx>
          <Bx mb={1}>
            <Typo variant="h5" color="primary">
              Zimmerart
            </Typo>
          </Bx>
          {roomTypes.map((rt, idx) => {
            const roomTypeResolved =
              roomTypesResolved[rt.roomType ?? ""].content || {};
            return (
              <Bx key={idx}>
                <CheckboxControl
                  label={roomTypeResolved.name}
                  checked={rt.roomAvailable ?? false}
                  onChange={(e) => {
                    if (roomTypes[idx]) {
                      const changed = { ...roomTypes[idx] };
                      changed.roomAvailable = !!!rt.roomAvailable;
                      const newArray = [...roomTypes];
                      newArray[idx] = changed;
                      setItem?.({
                        ...item,
                        content: { ...item?.content, roomTypesSubD: newArray },
                      });
                    }
                  }}
                />
                {rt.roomAvailable && rt.roomMale && (
                  <Bx ml={2}>
                    <CheckboxControl
                      label={"männlich"}
                      checked={rt.roomAvailableMale ?? false}
                      onChange={(e) => {
                        if (roomTypes[idx]) {
                          const changed = { ...roomTypes[idx] };
                          changed.roomAvailableMale = !!!rt.roomAvailableMale;
                          const newArray = [...roomTypes];
                          newArray[idx] = changed;
                          setItem?.({
                            ...item,
                            content: {
                              ...item?.content,
                              roomTypesSubD: newArray,
                            },
                          });
                        }
                      }}
                    />
                  </Bx>
                )}
                {rt.roomAvailable && rt.roomFemale && (
                  <Bx ml={2}>
                    <CheckboxControl
                      label={"weiblich"}
                      checked={rt.roomAvailableFemale ?? false}
                      onChange={(e) => {
                        if (roomTypes[idx]) {
                          const changed = { ...roomTypes[idx] };
                          changed.roomAvailableFemale =
                            !!!rt.roomAvailableFemale;
                          const newArray = [...roomTypes];
                          newArray[idx] = changed;
                          setItem?.({
                            ...item,
                            content: {
                              ...item?.content,
                              roomTypesSubD: newArray,
                            },
                          });
                        }
                      }}
                    />
                  </Bx>
                )}
              </Bx>
            );
          })}
        </Bx>
      </Grd>
      <Grd item xs={12} sm={6}>
        <Bx mb={1}>
          <Typo variant="h5" color="primary">
            Besondere Pflegeangebote
          </Typo>
        </Bx>
        {specialCareOffers.map((so, idx) => {
          return (
            <Bx key={idx}>
              <CheckboxControl
                label={
                  specialCareOffersResolved[so.specialCareOffer ?? ""].content
                    ?.name
                }
                checked={so.specialCareOfferAvailable ?? false}
                onChange={(e) => {
                  if (specialCareOffers[idx]) {
                    const changed = { ...specialCareOffers[idx] };
                    changed.specialCareOfferAvailable =
                      !!!so.specialCareOfferAvailable;
                    const newArray = [...specialCareOffers];
                    newArray[idx] = changed;
                    setItem?.({
                      ...item,
                      content: {
                        ...item?.content,
                        specialCareOffersSubD: newArray,
                      },
                    });
                  }
                }}
              />
            </Bx>
          );
        })}
      </Grd>
      <Grd item xs={12}>
        <Bx mb={1}>
          <Typo variant="h5" color="primary">
            <label htmlFor="field-freetext-stationary">Hinweise</label>
          </Typo>
        </Bx>
        <Bx>
          <EditControlFreeText
            id="field-freetext-stationary"
            value={item?.content?.description ?? ""}
            onValueChange={(value) => {
              setItem?.({
                ...item,
                content: { ...item?.content, description: value },
              });
            }}
          />
        </Bx>
      </Grd>
    </Grd>
  );
};
