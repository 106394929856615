import { Bx, Typo } from "@curry-group/mui-curcuma";
import React from "react";
import { IFacility } from "../../../../../model/facility";
import { IThing } from "../../../../../model/thing";

export const CareInfoSemiStationary: React.FC<{ item?: IThing<IFacility> }> = ({
  item,
}) => {
  return (
    <>
      <Bx mb={4}>
        <Typo variant="h3" component="h5" color="textSecondary">
          <Typo color="primary" component="span" variant="inherit">
            {item?.content?.placesFree || 0}
          </Typo>{" "}
          von{" "}
          <Typo color="primary" component="span" variant="inherit">
            {item?.content?.placesTotal || 0}
          </Typo>{" "}
          Plätzen{" "}
          <Typo
            color="primary"
            component="span"
            variant="inherit"
            style={{ textDecoration: "underline" }}
          >
            frei
          </Typo>
        </Typo>
      </Bx>

      {item?.content?.description ? (
        <>
          <Bx mb={2}>
            <Typo color="primary" component="strong" fontWeight="bold">
              Hinweise:
            </Typo>
          </Bx>
          <Bx>
            <Typo
              dangerouslySetInnerHTML={{
                __html: (item?.content?.description ?? "")
                  .replaceAll("<p>", "")
                  .replaceAll("</p>", ""),
              }}
            />
          </Bx>
        </>
      ) : (
        <Typo>Keine zusätzlichen Hinweise</Typo>
      )}
    </>
  );
};
