import { Bx, IconBtn } from '@curry-group/mui-curcuma';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import * as React from 'react';

export const PrintButton: React.FunctionComponent = () => {
  return (
    <Bx className="print-button">
      <Tooltip title="Diese Seite drucken" arrow>
        <IconBtn aria-label="Diese Seite drucken" color="quaternary" variant="contained" shape="rounded" size="medium" onClick={() => window.print()}>
          <FontAwesomeIcon icon={faPrint} />
        </IconBtn>
      </Tooltip>
    </Bx>
  );
};
