import { Bx, Ctn, LayoutImage, useBreakpoints } from '@curry-group/mui-curcuma';
import { Fade } from '@material-ui/core';
import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ContextSelection } from '../context-selection';
import { TabPanel } from '../tab-panel';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchFilter } from '../search-filter';

const useStyles = makeStyles(
  theme => ({
    tabPanelBx: {
      '& > .bx': {
        height: '100%',

        '& > .bx': {
          height: '100%',
          padding: theme.spacing(4, 4, 6, 4)
        }
      }
    }
  }),
  {
    name: 'TabPanelBx',
    meta: 'PPB_Custom-TabPanelBx'
  }
);

export const SearchSection: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const breakpoint = useBreakpoints();
  const [bgImageLoaded, setBgImageLoaded] = React.useState(false);
  const loc = useLocation();
  const history = useHistory();

  function handleBgImageLoaded() {
    !bgImageLoaded && setBgImageLoaded(true);
  }

  const searchParams = new URLSearchParams(loc.search);

  let fc = 'st';
  if (searchParams.has('fc')) {
    const newFc = searchParams.get('fc') ?? 'st';
    fc = newFc;
  }

  const setFc = (fc: string) => {
    if (['st', 'se', 'al'].includes(fc)) {
      searchParams.set('fc', fc);
      history.push(loc.pathname + '?' + searchParams.toString());
    }
  };

  return (
    <Bx
      pt={breakpoint.mdUp ? 4 : 2}
      pb={0}
      width="100%"
      minHeight={breakpoint.mdUp ? 800 : undefined}
      position="relative"
      display="flex"
      justifyContent="flex-end"
      className={'print-hidden'}
    >
      <Fade in={bgImageLoaded}>
        <div>
          <LayoutImage
            src="/assets/keyvisual.png"
            width="100%"
            height="100%"
            gradientDirection="to bottom"
            bgcolor={[
              { bgcolor: 'transparent', position: 0 },
              { bgcolor: theme.palette.background.default, position: 100 }
            ]}
            objectFit="cover"
            position="absolute"
            top={0}
            left={0}
            onLoadCallback={handleBgImageLoaded}
          />
        </div>
      </Fade>
      <Ctn maxWidth="lg" disableGutters={false}>
        <Bx display="flex" justifyContent="flex-end">
          <Bx display="flex" justifyContent="flex-start" pt={4} pb={4} flexDirection={breakpoint.mdUp ? 'row' : 'column'} width={breakpoint.mdUp ? 793 : '100%'}>
            <Bx>
              <ContextSelection value={fc} onValueChange={setFc} />
            </Bx>
            <Bx style={{ flexBasis: '100%' }}>
              <Bx
                className={classes.tabPanelBx}
                position="relative"
                style={{ zIndex: 3, boxShadow: theme.shadows[3], borderRadius: theme.shape.borderRadius, borderTopLeftRadius: 0 }}
                bgcolor="background.paper"
              >
                <TabPanel value="-" index="-">
                  <SearchFilter hideExtended hideReset hideSort />
                </TabPanel>
              </Bx>
            </Bx>
          </Bx>
        </Bx>
      </Ctn>
    </Bx>
  );
};
