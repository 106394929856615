import { Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

interface ICollapsibleFilterHead {
  children?: never;
  onClick: (e: React.MouseEvent) => void;
  expanded: boolean;
  title: string;
}

export const CollapsibleFilterHead: React.FunctionComponent<ICollapsibleFilterHead> = ({ onClick, expanded, title }) => {
  const theme = useTheme();
  return (
    <div onClick={onClick}>
      <Bx display="flex" alignItems="center" width="100%" style={{ borderBottom: `1px solid ${theme.palette.divider}` }} pb={1.5}>
        <Typo variant="h4" component="h4">
          {title}
        </Typo>
        <Bx ml="auto">
          <Tooltip arrow title={title + ' ' + (expanded ? 'Zuklappen' : 'Aufklappen')}>
            <IconBtn onClick={onClick} variant="text" size="small">
              <FontAwesomeIcon icon={expanded ? faMinus : faPlus} />
            </IconBtn>
          </Tooltip>
        </Bx>
      </Bx>
    </div>
  );
};
