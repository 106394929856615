import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

import { SearchResultsMapMarker } from './marker';

import { IFacility } from '../../../model/facility';
import { IThing } from '../../../model/thing';
import { FacilityGridItem } from '../../facility-item/grid';
import { Bx, Anohana, useBreakpoints } from '@curry-group/mui-curcuma';
import { defaultCoords } from '../../../parts';

const mapTenant = process.env.REACT_APP_MAP_TENANT || 'pflegeplatzboerse-7c4d6e49';

export const SearchResultsMap: React.FC<{ items?: IThing<IFacility>[] }> = ({ items }) => {
  const breakpoints = useBreakpoints();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState<IThing<IFacility>[]>([]);
  const myItems = items || [];
  const sameLocation: {
    [key: string]: IThing<IFacility>[];
  } = {};
  for (let item of myItems) {
    if (item.content?.lat && item.content?.lon) {
      const locKey = item.content.lat + '-' + item.content.lon;
      if (!sameLocation[locKey]) {
        sameLocation[locKey] = [];
      }
      sameLocation[locKey].push(item);
    }
  }
  const prevItem = () => {
    if (currentIndex < 1) {
      setCurrentIndex(selectedItems.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const nextItem = () => {
    if (currentIndex < selectedItems.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };
  return (
    <Bx width="100%">
      <MapContainer center={defaultCoords} zoom={12} scrollWheelZoom={false} style={{ height: 'calc(100vh - 360px)' }}>
        <TileLayer url={'https://maps.omniscale.net/v2/' + mapTenant + '/style.default/{z}/{x}/{y}.png'} />
        {Object.keys(sameLocation).map(sl => {
          const item = sameLocation[sl][0];
          return (
            <SearchResultsMapMarker
              key={sl}
              count={sameLocation[sl].length}
              item={item}
              onClick={() => {
                setCurrentIndex(0);
                setSelectedItems(sameLocation[sl]);
              }}
            />
          );
        })}
      </MapContainer>
      <Anohana
        keepMounted
        open={selectedItems && selectedItems.length > 0}
        onClose={() => {
          setSelectedItems([]);
        }}
      >
        <Bx bgcolor="#bfbfbf" width={breakpoints.xsDown ? 280 : 360}>
          <FacilityGridItem
            item={selectedItems[currentIndex]}
            to={'/detail/' + selectedItems[currentIndex]?.seo?.alias}
            showControls={selectedItems.length > 1}
            showClose
            onPrev={prevItem}
            onNext={nextItem}
            onClose={() => {
              setSelectedItems([]);
            }}
          />
        </Bx>
      </Anohana>
    </Bx>
  );
};
