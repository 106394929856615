import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { AssistedLiving, Stationary } from '../../../../model/facilitity-care-types';
import { IFacility } from '../../../../model/facility';
import { IThing } from '../../../../model/thing';
import { BoxedUnsortedList, InnerListBx, OuterListBx } from '../../../boxed-unsorted-list';

export const FacilityStructure: React.FC<{ item?: IThing<IFacility> }> = ({ item }) => {
  let listElements: React.ReactNode[] = [];

  if (item?.content?.facilityCareType?._id === AssistedLiving) {
    item?.content?.apartmentsSubD?.map(ap => {
      if (ap.apartmentCount !== undefined) {
        listElements.push(
          <Bx component="span">
            Davon <InnerListBx>{ap.apartmentCount}</InnerListBx>
            {item.content?.apartmentSizesResolved?.[ap.apartmentSize ?? ''].content?.namePlural
              ? item.content?.apartmentSizesResolved?.[ap.apartmentSize ?? ''].content?.namePlural
              : item.content?.apartmentSizesResolved?.[ap.apartmentSize ?? ''].content?.name}
          </Bx>
        );
      }
      return null;
    });
  } else if (item?.content?.facilityCareType?._id === Stationary) {
    item?.content?.roomTypesSubD?.map(rt => {
      if (rt.roomCount !== undefined) {
        listElements.push(
          <Bx component="span">
            Davon <InnerListBx>{rt.roomCount}</InnerListBx> Plätze in{' '}
            {item.content?.roomTypesResolved?.[rt.roomType ?? ''].content?.namePlural
              ? item.content?.roomTypesResolved?.[rt.roomType ?? ''].content?.namePlural
              : item.content?.roomTypesResolved?.[rt.roomType ?? ''].content?.name}
          </Bx>
        );
      }
      return null;
    });
  }

  return (
    <>
      <BoxedUnsortedList
        title="Größe der Einrichtung"
        fullHeight
        subHeadline={
          <Typo component="strong" fontWeight="bold">
            {item?.content?.facilityCareType?._id === AssistedLiving && (
              <>
                Insgesamt <OuterListBx>{item?.content?.placesTotal || 0}</OuterListBx> Appartements
              </>
            )}
            {item?.content?.facilityCareType?._id === Stationary && (
              <>
                Insgesamt <OuterListBx>{item?.content?.placesTotal || 0}</OuterListBx> Plätze
              </>
            )}
          </Typo>
        }
        items={listElements}
      />
    </>
  );
};
