import React, { useEffect, useState } from 'react';
import { Bx, CheckboxControl, Collapse } from '@curry-group/mui-curcuma';
import { IReferenceItem } from '../../../model/facility';
import { CollapsibleFilterHead } from '../collapsible-filter-head';

export const AssistedLivingFilter: React.FC<{
  collapsible?: boolean;
  hideExtended?: boolean;
  apartmentSizes: number[];
  setApartmentSizes: (value: number[]) => void;
}> = ({ collapsible, hideExtended, apartmentSizes, setApartmentSizes }) => {
  const [sizes, setSizes] = useState<IReferenceItem[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      const response = await fetch('/uwao-api/care-erlangen/filteritems/apartmentsizes');
      const json: IReferenceItem[] = await response.json();
      let sorted = json.sort(
        (a: any, b: any) => typeof a.content.name !== 'undefined' && typeof b.content.name !== 'undefined' && a.content.name.localeCompare(b.content.name)
      );
      setSizes(sorted);
    };
    if (!hideExtended) {
      load();
    }
  }, [hideExtended]);

  const RegularFilters = () => {
    return (
      <>
        {sizes.map((s, idx) => {
          return (
            <Bx key={s._id} mb={idx !== sizes.length - 1 ? 0.5 : undefined} width="100%">
              <CheckboxControl
                label={s.content?.name}
                checked={apartmentSizes.includes(s.sequentialNumber ?? 0)}
                onChange={(e, v) => {
                  if (s.sequentialNumber !== undefined) {
                    if (v) {
                      setApartmentSizes([...apartmentSizes, s.sequentialNumber]);
                    } else {
                      setApartmentSizes(apartmentSizes.filter(as => as !== s.sequentialNumber));
                    }
                  }
                }}
              />
            </Bx>
          );
        })}
      </>
    );
  };

  return (
    <>
      {!hideExtended && (
        <>
          {collapsible ? (
            <>
              <CollapsibleFilterHead title="Appartementgröße" expanded={expanded} onClick={() => setExpanded(!expanded)} />
              <Collapse expanded={expanded}>
                <Bx py={1.5} px={0.25}>
                  <RegularFilters />
                </Bx>
              </Collapse>
            </>
          ) : (
            <RegularFilters />
          )}
        </>
      )}
    </>
  );
};
