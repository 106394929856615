import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from '@material-ui/core/Button/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import _ from 'lodash';
import { IIndicatorProps, Indicator, isExternalUrl, numberToMs, TCurcumaColorsOnly } from '@curry-group/mui-curcuma';

export interface INavBarBtnProps extends Omit<ButtonProps, 'color'> {
  /**
   * Defines the active background-color
   */
  activeBgColor?: TCurcumaColorsOnly | React.CSSProperties['backgroundColor'];
  /**
   * Defines the active color
   */
  activeColor?: TCurcumaColorsOnly | React.CSSProperties['color'];
  /**
   * Defines the hover background-color
   */
  hoverBgColor?: TCurcumaColorsOnly | React.CSSProperties['backgroundColor'];
  /**
   * Defines the hover color
   */
  hoverColor?: TCurcumaColorsOnly | React.CSSProperties['color'];
  /**
   * Defines the ripple color
   */
  rippleColor?: TCurcumaColorsOnly | React.CSSProperties['color'];
  /**
   * Defines what the badge should be display
   */
  badgeContent?: IIndicatorProps['badgeContent'];
  /**
   * Defines how the badge should be displayed
   */
  badgeVariant?: IIndicatorProps['variant'];
  /**
   * Defines the color of the badge
   */
  badgeColor?: IIndicatorProps['color'];
  /**
   * Defines the where to redirect after click
   */
  to?: string;
  /**
   * Defines the window target which will be used if `to` is not empty
   */
  target?: '_blank' | '_self';
  /**
   * Defines if the Element should display as active
   */
  active?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
    display: 'flex',
    width: 'calc(100% + ' + theme.spacing(7) + 'px)',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(-3.5),
    marginRight: theme.spacing(-3.5),
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 0,
    position: 'relative',
    color: (props: INavBarBtnProps) => (props.active ? props.activeColor : 'inherit'),
    cursor: (props: INavBarBtnProps) => (props.active ? 'default' : 'pointer'),
    backgroundColor: (props: INavBarBtnProps) => (props.active ? props.activeBgColor : undefined),
    transition: theme.transitions.create(['background-color', 'color'], { duration: theme.transitions.duration.short, easing: theme.transitions.easing.easeInOut }),
    '&:first-child': {
      marginTop: theme.spacing(4)
    },
    '&:hover:not(:active)': {
      backgroundColor: (props: INavBarBtnProps) => (props.active ? props.activeBgColor : props.hoverBgColor),
      color: (props: INavBarBtnProps) => (props.active ? props.activeColor : props.hoverColor)
    },
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(1),
      width: theme.typography.pxToRem(33),
      justifyContent: 'center',
      '& > svg': {
        height: theme.typography.pxToRem(20),
        width: 'auto'
      }
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: (props: INavBarBtnProps) => (props.rippleColor ? props.rippleColor : undefined)
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 4,
      height: '100%',
      backgroundColor: theme?.palette?.accent?.main,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      opacity: (props: INavBarBtnProps) => (props.active ? 1 : 0),
      transition: `opacity ${numberToMs(theme.transitions.duration.shorter)} ${theme.transitions.easing.easeInOut}`
    }
  },
  badge: {
    '& > .MuiBadge-badge': {
      top: '-5px',
      right: '-8px'
    }
  }
}));

/**
 * The `NavBarBtn` component is an extension of the [Material-UI Button](https://material-ui.com/components/box/) and inherits all properties of it.
 * It also makes use of [Material-UI Badge](https://material-ui.com/components/badge/) and therefore accepts the property `showBadge` to display a Dot-Badge.
 */
export const NavBarBtn: React.FunctionComponent<INavBarBtnProps> = ({
  active,
  to,
  target,
  badgeContent,
  badgeVariant,
  badgeColor,
  startIcon,
  children,
  activeColor,
  activeBgColor,
  hoverColor,
  hoverBgColor,
  rippleColor,
  className,
  ...rest
}) => {
  const theme = useTheme();

  const btnActiveColor = _.get(theme.palette, activeColor + '.contrastText', _.get(theme.palette, activeColor as any, activeColor));
  const btnActiveBgColor = _.get(theme.palette, activeBgColor + '.main', _.get(theme.palette, activeBgColor as any, activeBgColor));
  const btnHoverColor = _.get(theme.palette, hoverColor + '.contrastText', _.get(theme.palette, hoverColor as any, hoverColor));
  const btnHoverBgColor = _.get(theme.palette, hoverBgColor + '.main', _.get(theme.palette, hoverBgColor as any, hoverBgColor));
  const btnRippleColor = _.get(theme.palette, rippleColor + '.main', _.get(theme.palette, rippleColor as any, rippleColor));

  const classes = useStyles({
    active,
    activeColor: btnActiveColor,
    activeBgColor: btnActiveBgColor,
    hoverColor: btnHoverColor,
    hoverBgColor: btnHoverBgColor,
    rippleColor: btnRippleColor
  });

  const badged = (
    <React.Fragment>
      {
        <Indicator badgeContent={badgeContent} color={badgeColor} variant={badgeVariant} className={classes.badge} overlap="rectangle">
          {startIcon}
        </Indicator>
      }
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {to ? (
        <Button
          component={to && !isExternalUrl(to) ? (Link as any) : 'a'}
          to={isExternalUrl(to) ? undefined : to}
          href={isExternalUrl(to) ? to : undefined}
          target={target}
          color="inherit"
          className={clsx(classes.root, className)}
          disableRipple={active}
          startIcon={badged}
          {...rest}
        >
          {children}
        </Button>
      ) : (
        <Button color="inherit" className={clsx(classes.root, className)} disableRipple={active} startIcon={badged} {...rest}>
          {children}
        </Button>
      )}
    </React.Fragment>
  );
};

NavBarBtn.defaultProps = {
  hoverColor: 'inherit',
  hoverBgColor: 'primary.dark',
  activeBgColor: 'transparent',
  activeColor: 'accent.main',
  rippleColor: 'accent.light'
};
