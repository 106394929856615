import { Typo, Link, Hamburger, Divider, IconBtn, useBreakpoints, Ctn, Bx } from '@curry-group/mui-curcuma';
import { AppBar, Drawer, Slide, Theme, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useContext } from 'react';
import { LogoHome } from '../logo-home';
import { faArrowAltFromRight, faArrowRight, faHospitalUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { INavigation } from '../../model/page';
import { AppContext } from '../../model/app-context';
import { NavBarBtn } from '../nav-bar-btn';

interface IHeaderLinks {
  id?: string;
  value: string;
  to: string;
  children?: IHeaderLinks[];
  mobileOnly?: boolean;
  topSpacer?: boolean;
}

const navToHeaderLinks: (nav: INavigation) => IHeaderLinks[] = nav => {
  const result: IHeaderLinks[] = nav.categories.map(cat => {
    return {
      id: cat.pageResolved._id,
      value: cat.pageResolved.content?.titleShort || cat.pageResolved.content?.title || '',
      to: '/startseite/' + cat.pageResolved.seo?.alias,
      children: cat.pages?.map(page => {
        return {
          id: page.pageResolved._id,
          value: page.pageResolved.content?.titleShort || page.pageResolved.content?.title || '',
          to: '/startseite/' + cat.pageResolved.seo?.alias + '/' + page.pageResolved.seo?.alias
        };
      })
    };
  });
  result.push(
    ...[
      {
        id: '5a0025d1-9289-4433-a13a-faf0daf3e077',
        value: 'Impressum',
        to: '/startseite/meta/impressum',
        mobileOnly: true,
        topSpacer: true
      },
      {
        id: '24b10170-d582-43a9-b7a3-3b96797951aa',
        value: 'Datenschutz',
        to: '/startseite/meta/datenschutz',
        mobileOnly: true
      },
      {
        id: 'dd683b5c-3b3f-4d3b-8028-4a03a12454dd',
        value: 'Barrierefreiheit',
        to: '/startseite/meta/barrierefreiheit',
        mobileOnly: true
      }
    ]
  );
  return result;
};

const useStyles = makeStyles(
  theme => ({
    header: {
      zIndex: 10001,
      '& > .bx': {
        height: theme?.custom?.project?.appBar?.desktop?.height,
        [theme.breakpoints.down('sm')]: {
          height: theme?.custom?.project?.appBar?.mobile?.height
        }
      }
    },
    mobileLinks: {
      paddingLeft: 0,
      paddingRight: 0,
      margin: 0,
      width: '100%',
      minHeight: '64px',
      '&:first-child': {
        marginTop: 0
      },
      '&:before': {
        display: 'none'
      }
    },
    mainNavItem: {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: -5,
        left: '50%',
        height: 3,
        width: 0,
        backgroundColor: theme?.palette?.quaternary?.main,
        transition: theme.transitions.create(['width', 'left'], {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeInOut
        })
      },
      '&:hover': {
        '&:after': {
          left: 0,
          width: '100%'
        }
      }
    },
    mainNavItemActive: {
      color: theme.palette.primary.main,
      '&:after': {
        left: 0,
        width: '100%',
        backgroundColor: theme.palette.primary.main
      }
    }
  }),
  {
    name: 'TabPanelBox',
    meta: 'PPB_Custom-TabPanelBox'
  }
);

type TSubLinks = {
  open: boolean;
  parentLabel?: string;
  children?: IHeaderLinks[];
};

export const HeaderV2: React.FC<{ navigation?: INavigation }> = ({ navigation }) => {
  const location = useLocation();
  const theme = useTheme();
  const [drawer, setDrawer] = useState(false);
  const [subLinks, setSubLinks] = useState<TSubLinks>({ open: false });
  const [headerLinks, setHeaderLinks] = useState<IHeaderLinks[]>([]);
  const classes = useStyles();
  const breakpoint = useBreakpoints();

  const { state } = useContext(AppContext);
  useEffect(() => {
    if (navigation && !headerLinks.length) setHeaderLinks(navToHeaderLinks(navigation));
  }, [navigation, headerLinks.length]);

  return (
    <AppBar position="sticky" color="transparent" className={clsx(classes.header, 'print-hidden')} component="header">
      <Bx bgcolor="background.paper">
        <Drawer
          anchor="left"
          open={drawer}
          onClose={() => {
            setDrawer(false);
          }}
          style={{ zIndex: 10000 }}
        >
          <Bx
            height="100%"
            width="350px"
            style={{
              paddingTop: breakpoint.smDown ? `${theme?.custom?.project?.appBar?.mobile?.height}px` : `${theme?.custom?.project?.appBar?.desktop?.height}px`,
              overflow: subLinks.open ? 'hidden' : undefined
            }}
          >
            {headerLinks.map((hl, idx) => {
              return <OffCanvasItem key={'mobile_' + hl.id} index={idx} data={hl} theme={theme} setSubLinks={setSubLinks} setDrawer={setDrawer} subCanvasOpen={subLinks} />;
            })}
          </Bx>
          <Slide direction="right" in={subLinks.open}>
            <Bx
              height="100%"
              width="350px"
              bgcolor="background.paper"
              style={{
                position: 'absolute',
                borderLeft: `10px solid ${theme.palette.quaternary?.main}`,
                paddingTop: breakpoint.smDown ? `${theme?.custom?.project?.appBar?.mobile?.height}px` : `${theme?.custom?.project?.appBar?.desktop?.height}px`
              }}
            >
              <Bx pt={4}>
                {subLinks.parentLabel && (
                  <Bx px={4}>
                    <Typo variant="h4" color="primary">
                      {subLinks.parentLabel}
                    </Typo>
                  </Bx>
                )}
                <NavBarBtn
                  style={{ minHeight: 0 }}
                  className={classes.mobileLinks}
                  hoverBgColor="primary.main"
                  hoverColor="background.paper"
                  onClick={() => setSubLinks({ ...subLinks, open: false })}
                >
                  <FontAwesomeIcon icon={faArrowAltFromRight} style={{ marginRight: '16px' }} />
                  <Typo variant="body1">Zurück</Typo>
                </NavBarBtn>
                <Divider my={2} />
              </Bx>
              <Bx>
                {subLinks.children?.length &&
                  subLinks.children.map(item => {
                    return (
                      <NavBarBtn
                        key={'offcanvas_' + item.id}
                        to={item.to}
                        className={classes.mobileLinks}
                        hoverBgColor="primary.main"
                        hoverColor="background.paper"
                        activeColor="primary.dark"
                        activeBgColor="quaternary.light"
                        active={location.pathname === item.to}
                        onClick={() => {
                          setSubLinks({ ...subLinks, open: false });
                          setDrawer(false);
                        }}
                      >
                        <Typo variant="body1">{item.value}</Typo>
                      </NavBarBtn>
                    );
                  })}
              </Bx>
            </Bx>
          </Slide>
        </Drawer>

        {!breakpoint.mdUp && (
          <Ctn maxWidth="lg" style={{ height: '100%' }} disableGutters={false}>
            <Bx height="100%" display="flex" justifyContent="space-between" alignItems="center">
              <LogoHome />
              <Hamburger title="Menü öffnen" aria-label="Menü öffnen"
                toggle={() => {
                  setDrawer(!drawer);
                }}
                toggled={drawer === true}
              />
            </Bx>
          </Ctn>
        )}
        {breakpoint.mdUp && (
          <Ctn maxWidth="lg" style={{ height: '100%' }} disableGutters={false}>
            <Bx display="grid" gridGap={16} gridTemplateColumns="auto 1fr" height="100%" alignItems="center">
              <Bx>
                <LogoHome />
              </Bx>
              <Bx component="nav" display="grid" gridGap={breakpoint.lgUp ? 48 : 20} style={{ gridAutoFlow: 'column' }} ml="auto">
                {state?.loggedIn && (
                  <Tooltip arrow title="Meine Einrichtungen">
                    <div>
                      <MainNavItem noMargin to="/my-facilities" label={<FontAwesomeIcon icon={faHospitalUser} />} active={location.pathname.indexOf('/my-facilities') === 0} />
                    </div>
                  </Tooltip>
                )}
                {headerLinks.map(hl => {
                  return !hl.mobileOnly && <MainNavItem noMargin key={hl.id} to={hl.to} label={hl.value} active={location.pathname.indexOf(hl.to) === 0} />;
                })}
              </Bx>
            </Bx>

            {/* <Bx height="100%" display="flex" alignItems="center">
              <Grd container>
                <Grd item sm={3} lg={4}>
                  <LogoHome />
                </Grd>
                <Grd item sm={9} lg={8}>
                  <Bx display="flex" justifyContent="flex-end" alignItems="center" height="100%">
                    {state?.loggedIn && (
                      <Tooltip arrow title="Meine Einrichtungen">
                        <div>
                          <MainNavItem
                            noMargin
                            to="/my-facilities"
                            label={<FontAwesomeIcon icon={faHospitalUser} />}
                            active={location.pathname.indexOf('/my-facilities') === 0}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {headerLinks.map(hl => {
                      return !hl.mobileOnly && <MainNavItem key={hl.id} to={hl.to} label={hl.value} active={location.pathname.indexOf(hl.to) === 0} />;
                    })}
                  </Bx>
                </Grd>
              </Grd>
            </Bx> */}
          </Ctn>
        )}
      </Bx>
    </AppBar>
  );
};

interface IMainNavItemProps {
  to: string;
  label: string | React.ReactNode;
  active?: boolean;
  noMargin?: boolean;
}

const MainNavItem: React.FunctionComponent<IMainNavItemProps> = ({ noMargin, to, label, active }) => {
  const classes = useStyles();

  return (
    <Bx ml={!noMargin ? { md: 5, lg: 8, xl: 10 } : undefined}>
      <Link variant="h4" style={{ fontWeight: 'normal' }} to={to} underline={'none'} className={clsx(classes.mainNavItem, active && classes.mainNavItemActive)}>
        {label}
      </Link>
    </Bx>
  );
};

interface IOffCanvasItemProps {
  index: number;
  data: IHeaderLinks;
  theme: Theme;
  setSubLinks: (e: TSubLinks) => void;
  setDrawer: (e: boolean) => void;
  subCanvasOpen?: TSubLinks;
}

const OffCanvasItem: React.FunctionComponent<IOffCanvasItemProps> = ({ index, data, theme, setSubLinks, setDrawer, subCanvasOpen }) => {
  const classes = useStyles();

  return (
    <Bx style={{ position: 'relative' }} mt={index === 0 ? 3 : undefined}>
      {data.topSpacer && <Divider my={4} />}
      <NavBarBtn
        to={data.to}
        className={classes.mobileLinks}
        hoverBgColor="primary.main"
        hoverColor="background.paper"
        onClick={() => setDrawer(false)}
        tabIndex={subCanvasOpen?.open ? -1 : 0}
      >
        <Bx display="flex" justifyContent="space-between" alignItems="center" pr={data.children?.length ? 3 : 4}>
          <Typo variant="body1">{data.value}</Typo>
        </Bx>
      </NavBarBtn>
      {data.children?.length && (
        <Bx style={{ position: 'absolute', top: `calc(50% - ${theme?.custom?.iconBtn?.small?.height}px / 2)`, right: theme.spacing(3) }}>
          <IconBtn
            tabIndex={subCanvasOpen?.open ? -1 : 0}
            variant="contained"
            size="small"
            shape="rounded"
            onClick={() =>
              setSubLinks({
                children: data.children,
                parentLabel: data.value,
                open: true
              })
            }
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </IconBtn>
        </Bx>
      )}
    </Bx>
  );
};
