export const CONFIG_PROPERTY_NAME = 'rsConf';
export const CUSTOMER_PROPERTY_NAME = '__rsCustomer';
export const RS_PROPERTY_NAME = 'ReadSpeaker';

export interface IReadSpeakerConfigSettings {
  /**
   * @default hlon
   * @description This setting toggles highlighting on or off (hlon or hloff). If set to off, it overrides the rsConf.settings.hl value.
   * @type {string}
   */
  hltoggle?: 'hlon' | 'hloff';

  /**
   * @default wordsent
   * @description Which highlighting style to use. Default setting is highlighting both word and sentence.
   * @type {string}
   */
  hl?: 'wordsent' | 'word' | 'sent';

  /**
   * @default #a4cbff
   * @description The color that will be used for highlighting words, expressed as a valid CSS color value.
   * @type {string}
   */
  hlword?: string;

  /**
   * @default #000000
   * @description The color that will be used for the text in the highlighted elements, expressed as a valid CSS color value.
   * @type {string}
   */
  hltext?: string;
}

export interface IReadSpeakerConfigUICallbacks {
  close?(): void;
  beforeclose?(): void;
  open?(): void;
  pause?(): void;
  play?(): void;
  progresschanged?(progressInPercent: number): void;
}

export interface IReadSpeakerGeneralConfig {
  usePost?: boolean;
}

export interface IReadSpeakerConfig {
  cb?: { ui?: IReadSpeakerConfigUICallbacks };
  general?: IReadSpeakerGeneralConfig;
  settings?: IReadSpeakerConfigSettings;
  params?: string;
}

export interface IReadSpeakerPlayerAPI {
  play(): void;
  stop(): void;
  pause(): void;
  restart(): void;

  mute(): void;
  volUp(): void;
  volDown(): void;

  /**
   * @param {number} volume 0 - 100
   */
  setVolume(volume: number): void;

  forward(): void;
  rewind(): void;
}

export interface IReadSpeaker {
  PlayerAPI: IReadSpeakerPlayerAPI;
  ui: { addClickEvents?(): void };
  /**
   * Use this function to call functions of readspeaker
   * @param {Function} fn
   * @example q(() => ReadSpeaker.PlayerAPI.stop())
   */
  q(fn: Function): void;
}

declare var ReadSpeaker: IReadSpeaker;

export const WebReaderButtonUri = '//app-eu.readspeaker.com/cgi-bin/rsent';

export function customerIdentifier() {
  return (window as any)[CUSTOMER_PROPERTY_NAME];
}

export function stopPlayback() {
  if ((window as any)[RS_PROPERTY_NAME]) ReadSpeaker.q(() => ReadSpeaker.PlayerAPI.stop());
}
export function addClickEvents() {
  if ((window as any)[RS_PROPERTY_NAME]) {
    ReadSpeaker.q(function () {
      ReadSpeaker.ui.addClickEvents?.();
    });
  }
}

export function setReadspeakerUsePost() {
  if (!customerIdentifier()) return console.warn('readspeaker was not initialized properly');

  if (!(window as any)[CONFIG_PROPERTY_NAME]) (window as any)[CONFIG_PROPERTY_NAME] = {};

  const config = (window as any)[CONFIG_PROPERTY_NAME] as IReadSpeakerConfig;
  if (!config.general) config.general = {};

  config.params = `//f1-eu.readspeaker.com/script/${customerIdentifier()}/ReadSpeaker.js?pids=embhl`;
  config.general.usePost = true;
}

export function initialize(customerId: number, onLoadCallback?: Function, config?: IReadSpeakerConfig) {
  if (customerIdentifier()) return; // already initialized
  (window as any)[CUSTOMER_PROPERTY_NAME] = customerId;

  if (config) (window as any)[CONFIG_PROPERTY_NAME] = config;

  const webReaderScriptUri = `//cdn1.readspeaker.com/script/${customerId}/webReader/webReader.js?pids=wr`;

  /* create script */
  var webReaderScript = document.createElement('script');
  webReaderScript.onload = function () {
    onLoadCallback?.();
  };
  webReaderScript.src = webReaderScriptUri;
  webReaderScript.id = 'rs_req_Init';
  document.getElementsByTagName('head')[0].appendChild(webReaderScript);
}
