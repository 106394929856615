import React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { Hidden } from '@material-ui/core';
import { Bx, Typo, useBreakpoints, Grd, TabItem, TabList, Anohana } from '@curry-group/mui-curcuma';
// import { Skeleton } from '@material-ui/lab';
import dayjs from 'dayjs';

// import { FacilityDetailItemLocation } from './location';
// import { FacilityDetailItemOverview } from './overview';
import { FacilityStructure } from './facility-structure';
import { LocationMap } from './location-map';
import { CareInfo } from './care-info';
import { IFacility, IReferenceItem } from '../../../model/facility';
import { IThing } from '../../../model/thing';
import { FacilityItemOffers } from './offers';
import { FacilityItemDetailGallery } from './detail-gallery';
import { ContactTab } from './info-tabs/contact-tab';
import { BusinessHoursTab } from './info-tabs/business-hours-tab';
import { DownloadsTab } from './info-tabs/downloads-tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { BoxedUnsortedList } from '../../boxed-unsorted-list';
import { AssistedLiving, Stationary, SemiStationary } from '../../../model/facilitity-care-types';

export interface ICareTypePlaces {
  [key: string]: { free: number; total: number };
}
export interface ICareTypeRoomTypePlaces {
  [key: string]: {
    [key: string]: {
      total: number;
      neutral: number;
      male: number;
      female: number;
    };
  };
}

export interface ICarePlacesDict {
  careTypePlaces: ICareTypePlaces;
  careTypeRoomTypePlaces: ICareTypeRoomTypePlaces;
}

export interface ICareTypesRoomTypesResolved {
  careTypesResolved?: {
    [key: string]: IReferenceItem;
  };
  roomTypesResolved?: {
    [key: string]: IReferenceItem;
  };
}

export interface ICarePlacesTableProps {
  careTypes: string[];
  roomTypes: string[];
  dict: ICarePlacesDict;
  resolved: ICareTypesRoomTypesResolved;
}

export const FacilityDetailItem: React.FC<{ item?: IThing<IFacility> }> = ({ item }) => {
  const breakpoints = useBreakpoints();
  const theme = useTheme();

  const [value, setValue] = React.useState(0);
  const [showMap, setShowMap] = React.useState(false);
  const StationaryOrAssisted = item?.content?.facilityCareType?._id === Stationary || item?.content?.facilityCareType?._id === AssistedLiving;
  const IsStationary = item?.content?.facilityCareType?._id === Stationary;
  const IsSemiStationary = item?.content?.facilityCareType?._id === SemiStationary;
  const IsAssisted = item?.content?.facilityCareType?._id === AssistedLiving;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  let careTypes: string[] = [];
  if (IsStationary) {
    item?.content?.carePlaceTypesSubD?.forEach((cpt, idx) => {
      let carePlaceType = item.content?.carePlaceTypesResolved?.[cpt.carePlaceType ?? ''];
      if (carePlaceType?.content?.name && !(cpt as any).carePlaceTypeIgnoreCount) {
        careTypes.push(carePlaceType.content.name);
      }
    });
  } else if (SemiStationary) {
    item?.content?.careTypes?.forEach((cpt, idx) => {
      cpt.content?.name && careTypes.push(cpt.content?.name);
    });
  }

  let specialCareOffers: string[] = [];
  if (item && item.content?.specialCareOffersResolved) {
    Object.entries(item?.content?.specialCareOffersResolved).forEach(el => {
      if (el[1].content?.name) {
        specialCareOffers.push(el[1].content.name);
      }
      return null;
    });
  }

  return (
    <>
      <Bx bgcolor="background.paper" boxShadow={2} borderRadius="borderRadius" mb={8} position="relative">
        <Grd container>
          <Grd item xs={12} md={6} lg={6} xl={7} className="print-hidden">
            {item && (
              <Bx height="100%" minHeight={breakpoints.mdUp ? 598 : 386}>
                <FacilityItemDetailGallery item={item} />
              </Bx>
            )}
          </Grd>
          <Grd item xs={12} md={6} lg={6} xl={5}>
            <Bx borderBottom={1} border={0} borderColor="divider" id="detail-tabs">
              <TabList
                value={value}
                variant="scrollable"
                scrollButtons="off"
                onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
              >
                <TabItem label="Kontakt" />
                <TabItem label="Öffnungszeiten der Verwaltung" />
                <TabItem label="Downloads" />
              </TabList>
            </Bx>
            <Bx p={4} className="print-hidden">
              {item && value === 0 && <ContactTab item={item} setShowMap={setShowMap} showMap={showMap} />}
              {item && value === 1 && <BusinessHoursTab item={item} />}
              {item && value === 2 && <DownloadsTab item={item} />}
            </Bx>
            <Bx className="print-only">
              <Bx className="print-tab-item">
                <div className="print-as-h3">Kontakt</div>
                <ContactTab item={item} setShowMap={setShowMap} showMap={showMap} />
              </Bx>

              <Bx className="print-tab-item">
                <div className="print-as-h3">Öffnungszeiten der Verwaltung</div>
                <BusinessHoursTab item={item} />
              </Bx>

              <Bx className="print-tab-item downloads">
                <div className="print-as-h3">Downloads</div>
                <DownloadsTab item={item} />
              </Bx>
            </Bx>
          </Grd>
        </Grd>
        {item?.content?.lastUpdate !== undefined && (
          <Bx position="absolute" bottom={4} right={4}>
            <Typo variant="subtitle2" align="right">
              Aktualisiert am {dayjs(item.content.lastUpdate * 1000).format('DD.MM.YYYY')}
            </Typo>
          </Bx>
        )}
      </Bx>

      <Bx mb={4} display={{ xs: 'none', md: 'block' }}>
        <Grd container spacing={6}>
          <Grd item xs={12} md={4} lg={3}>
            <Typo variant="h2" component="h4" color="textSecondary" className="print-hidden">
              Freie Plätze
            </Typo>
          </Grd>
          <Grd item xs={12} md={8} lg={9}>
            <Typo variant="h2" component="h4" color="textSecondary" className="print-hidden">
              Merkmale und Ausstattung
            </Typo>
          </Grd>
        </Grd>
      </Bx>

      <Typo variant="h2" component="h4" color="textSecondary" className="print-only print-as-h3">
        Freie Plätze
      </Typo>
      <Grd container spacing={6}>
        <Grd item xs={12} md={4} lg={3} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Bx mb={2} display={{ xs: 'block', md: 'none' }}>
            <Typo variant="h2" component="h4" color="textSecondary" className="print-hidden">
              Freie Plätze
            </Typo>
          </Bx>
          <Bx bgcolor="background.paper" boxShadow={2} borderRadius="borderRadius" mb={4} p={3} className="care-info">
            <CareInfo item={item} />
          </Bx>
        </Grd>
        <Grd
          item
          xs={12}
          md={8}
          lg={9}
          style={{ borderLeft: breakpoints.mdUp ? `1px solid ${theme.palette.divider}` : undefined, paddingTop: 0, paddingBottom: 0 }}
          className="care-type-info"
        >
          <Typo variant="h2" component="h4" color="textSecondary" className="print-only print-as-h3">
            Merkmale und Ausstattung
          </Typo>
          <Bx mb={2} display={{ xs: 'block', md: 'none' }}>
            <Typo variant="h2" component="h4" color="textSecondary" className="print-hidden">
              Merkmale und Ausstattung
            </Typo>
          </Bx>
          <Grd container spacing={3}>
            {(item?.content?.facilityCareType?.content?.name || careTypes.length > 0) && (
              <Grd item xs={12} md={StationaryOrAssisted ? 5 : IsSemiStationary ? 6 : 12}>
                <Bx gridGap={24} display="grid" height={IsAssisted || IsSemiStationary ? '100%' : undefined}>
                  {item?.content?.facilityCareType?.content?.name && (
                    <BoxedUnsortedList title="Versorgungsformen" fullHeight={IsAssisted} items={[item?.content?.facilityCareType?.content?.name]} />
                  )}
                  {!IsSemiStationary && careTypes.length > 0 && <BoxedUnsortedList title="Pflegeplatzarten" items={careTypes} />}
                </Bx>
              </Grd>
            )}
            {StationaryOrAssisted && (
              <Grd item xs={12} md={7}>
                <FacilityStructure item={item} />
              </Grd>
            )}
            {IsSemiStationary && (
              <Grd item xs={12} md={6}>
                {careTypes.length > 0 && <BoxedUnsortedList title="Art der Betreuung" items={careTypes} />}
              </Grd>
            )}
          </Grd>

          <Bx mt={3}>
            <Bx gridGap={24} display="grid">
              {specialCareOffers.length > 0 && <BoxedUnsortedList title="Besondere Pflegeangebote" asGrid items={specialCareOffers} />}
              <FacilityItemOffers item={item} />
            </Bx>
          </Bx>
        </Grd>
      </Grd>
      {!!item?.content?.lat && !!item?.content?.lon && (
        <Anohana open={showMap} onClose={() => setShowMap(false)} maxWidth="lg" fullWidth transition="zoom">
          <Bx position="relative" height="70vh">
            <Bx position="absolute" top={8} right={8} zIndex={9999}>
              <Bx
                component="button"
                bgcolor="common.white"
                hoverBgcolor="primary.main"
                focusBgcolor="primary.dark"
                ring={3}
                ringColor="text.primary"
                ringOpacity={20}
                hoverRingOpacity={40}
                color="text.primary"
                hoverColor="primary.contrastText"
                focusColor="primary.contrastText"
                focusRing={3}
                focusRingColor="background.paper"
                focusRingOpacity={70}
                outline="none"
                transition="colors"
                border={0}
                height={48}
                p={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="borderRadius2"
                fontSize="body2"
                fontWeight="bold"
                style={{ cursor: 'pointer' }}
                aria-label="Karte schließen"
                onClick={() => setShowMap(!showMap)}
              >
                <Bx mr={1} fontSize="body1">
                  <FontAwesomeIcon icon={faTimes} />
                </Bx>
                Karte schließen
              </Bx>
            </Bx>
            <LocationMap item={item} />
          </Bx>
        </Anohana>
      )}
    </>
  );
};
