import React from 'react';
import { Bx, Btn, IconBtn } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

export const Controls: React.FC<{
  showControls?: boolean;
  showClose?: boolean;
  onClose?: () => void;
  onPrev?: () => void;
  onNext?: () => void;
}> = ({ showClose, showControls, onClose, onPrev, onNext }) => {
  return (
    <Bx width="100%" display="flex" justifyContent={showControls ? 'space-between' : 'center'} alignItems="center" p={2}>
      {showControls && (
        <IconBtn
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            onPrev?.();
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </IconBtn>
      )}
      {showClose && (
        <Btn
          color="primary"
          variant="contained"
          onClick={() => {
            onClose?.();
          }}
        >
          Schließen
        </Btn>
      )}
      {showControls && (
        <IconBtn
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            onNext?.();
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconBtn>
      )}
    </Bx>
  );
};
