import { TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';

const useStyles = makeStyles({
  numberField: {
    flexShrink: 0
  },
  input: {
    width: '2.5rem',
    padding: '9px 7px',
    minWidth: 50,
    textAlign: 'right',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield'
    }
  }
});

export const EditControlNumber: React.FC<{
  name?: string;
  value?: number;
  onValueChange?: (value: number | '') => void;
  min?: number;
  max?: number;
  id?: string;
}> = ({ value, onValueChange, min = 0, max = 9999, name, id }) => {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState<number | ''>('');

  const handleNumberChange = (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let int: number | '' = '';
    try {
      int = parseInt(ev.target.value);
      if (int < min) {
        int = min;
      } else if (int > max) {
        int = max;
      } else if (isNaN(int)) {
        int = '';
      }
    } catch {}
    if (onValueChange) {
      if (int !== value) {
        onValueChange(int);
      }
    } else {
      if (int !== internalValue) {
        setInternalValue(int);
      }
    }
  };
  return (
    <TextField
      id={id}
      name={name}
      variant="outlined"
      type="number"
      className={classes.numberField}
      value={onValueChange ? value ?? '' : internalValue}
      onChange={handleNumberChange}
      inputProps={{ className: classes.input }}
    />
  );
};
