import { Bx } from '@curry-group/mui-curcuma';
import React from 'react';

export const PBList: React.FC = ({ children }) => {
  return (
    <Bx pl={2} pr={2}>
      <ul style={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }}>{children}</ul>
    </Bx>
  );
};
