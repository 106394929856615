import React, { FC, useEffect } from 'react';
import { addClickEvents, customerIdentifier, stopPlayback, WebReaderButtonUri } from '../lib';

export interface IReadSpeakerButtonProps {
  /**
   * Einzelne oder Array von Container-IDs welche vorgelesen werden sollen
   */
  readId?: string | string[];

  /**
   * Wird verwendet um den Readspeaker zu stoppen, sollte die Komponente bestehen bleiben. Bsp.: Eine Detailansicht lädt den Inhalt anhand eines Alias.
   * Das führt unter Umständen zu keinem Unmount. Damit der Button trotzdem initialisiert wird, kann hier ein Wert angegeben werden.
   * Zum Beispiel die _id der Seite.
   */
  identifier?: string;

  /**
   * Einzelne oder Array von CSS-Klassen welche vorgelesen werden sollen
   */
  readClass?: string | string[];

  /**
   * Schaltflächen-Title (Hover Tooltip, Default: "Um den Text anzuhören, verwenden Sie bitte ReadSpeaker webReader")
   */
  title?: string;

  /**
   * Schaltflächen Inhalt (Default: "Vorlesen")
   */
  caption?: string;
  className?: string;
  style?: React.CSSProperties;
}

/**
 *
 * @param readId Einzelne oder Array von Container-IDs welche vorgelesen werden sollen
 * @param {optional} readClass Einzelne oder Array von CSS-Klassen welche vorgelesen werden sollen
 * @param title Schaltflächen-Title (Hover Tooltip, Default: "Um den Text anzuhören, verwenden Sie bitte ReadSpeaker webReader")
 * @param caption Schaltflächen Inhalt (Default: "Vorlesen")
 * @returns
 */
export const ReadSpeakerButton: FC<IReadSpeakerButtonProps> = ({
  readId,
  readClass,
  title = 'Um den Text anzuhören, verwenden Sie bitte ReadSpeaker webReader',
  caption = 'Vorlesen',
  identifier,
  className,
  style
}) => {
  const customerId = customerIdentifier();

  useEffect(() => {
    addClickEvents();
    return () => stopPlayback();
  }, [identifier]);

  if (!customerId) return <></>;

  const currentUrl = encodeURIComponent(window.location.href);
  const readIdParam = readId?.toString();
  const readClassParam = readClass?.toString();

  let href = `${WebReaderButtonUri}?`;

  // customer id
  href += `customerid=${customerId}`;

  // url
  href += `&url=${currentUrl}`;

  // language
  href += '&lang=de_de';

  // read id
  if (readIdParam) href += `&readid=${readId}`;

  // read class
  if (readClassParam) href += `&readclass=${readClass}`;

  return (
    <div id="readspeaker_button1" className={'print-hidden rs_skip rsbtn rs_preserve' + (className ? ' ' + className : '')} style={style} key={identifier}>
      <a rel="nofollow" className="rsbtn_play" title={title} href={href}>
        <span className="rsbtn_left rsimg rspart">
          <span className="rsbtn_text">
            <span>{caption}</span>
          </span>
        </span>
        <span className="rsbtn_right rsimg rsplay rspart"></span>
      </a>
    </div>
  );
};
